import axios from "axios";
import "moment/locale/fr";

class Salesforce {
  constructor(options) {
    const endpoint = options.eda ? "/eda" : "/";
    this.axiosSfConnect = axios.create({
      baseURL: `${process.env.REACT_APP_ENDPOINT}${endpoint}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async checkSlot(slotId, places) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/checkSlot",
      params: { id: slotId, places: places },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Erreur récupération créneau");
    }
  }

  async createLead(lead) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/lead/createLead",
      data: lead,
    });

    if (query.data.success) {
      return query.data.lead;
    } else {
      throw new Error("Erreur création du lead");
    }
  }

  async createCampaignMember(campaignMember) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/lead/createCampaignMember",
      data: campaignMember,
    });

    if (query.data.success) {
      return query.data.campaignMember;
    } else {
      throw new Error("Erreur création du membre de campagne");
    }
  }

  async createCampaignMemberExternal(campaignMember, externalId) {
    const query = await this.axiosSfConnect({
      method: "post",
      url:"/lead/createCampaignMemberExternal",
      data:{campaignMember,externalId}
    })
    if (query.data.success) {
      return query.data.campaignMember;
    } else {
      throw new Error("Erreur création du memebre de campagne")
    }
  }

  async createTask(task) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/lead/createTask",
      data: task,
    });

    if (query.data.success) {
      return query.data.task;
    } else {
      throw new Error("Erreur création tache");
    }
  }

  async getAcademicLevels(recordTypeId, division, academics = null) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getAcademicLevel",
      params: {
        division: division,
        recordTypeId: recordTypeId,
        academics: academics,
      },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible de récupérer les niveaux académiques");
    }
  }

  async getCountries(id, firstValues) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCountries",
      params: { id: id, firstValues: firstValues },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les pays");
    }
  }


  async getCampaign(productId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCampaigns",
      params: { productId },
    });
    //si le champ est rempli productId recupere la campagne "externe"
    if (query.data.success && query.data.data.length > 0) {
      return query.data.data[0];
    } else {
      throw new Error("Impossible récupérer les campagnes");
    }
  }
  async getCampaigns(division, school, type) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCampaigns",
      params: { division, school, type },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les campagnes");
    }
  }

  async getCampaignsCandidature(division, school, type) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCampaignsCandidature",
      params: { division, school, type },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les campagnes");
    }
  }

  async getCampus(recordTypeId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCampus",
      params: { recordTypeId },
    });

    if (query.data.success) {
      return query.data.campus;
    } else {
      throw new Error("Impossible récupérer les campus");
    }
  }

  async getCursus(ecole, recordTypeId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCursus",
      params: { ecole, recordTypeId },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les cursus");
    }
  }

  async getDepartments(ecole, recordTypeId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getDepartments"
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les départements");
    }
  }

  async getRecordTypeId(name) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getRecordTypeId",
      params: { name },
    });

    if (query.data.success) {
      return query.data.Id;
    } else {
      throw new Error("Impossible récupérer record type id");
    }
  }


  formatCreneaux(slots) {
    let creneaux = [];
    for (const slot of slots) {
      let label = "De " + slot.SlotStartTime__c + " à " + slot.SlotEndTime__c;
      if (slot.RemainingPlaces__c <= 0) {
        label += " - Complet";
      } else {
        label += " - reste " + slot.RemainingPlaces__c + " place";
      }
      if (slot.RemainingPlaces__c > 1) {
        label += "s";
      }

      creneaux.push({
        label: label,
        places: slot.RemainingPlaces__c,
        value: slot.Id,
      });
    }

    return creneaux;
  }
}

export default Salesforce;
