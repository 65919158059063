import PropTypes from "prop-types"
import { MenuItem } from "@mui/material"
import { Controller } from "react-hook-form"
import DropdownCommon from "./DropdownCommon"

const DropdownCreneaux = ({ control, disabled, label, multiple, name, options, ...rest }) => {

  const renderOptions = (options) => {
    return options.map((option) => (
      <MenuItem disabled={option.places <= 0} key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))
  }

  return (
    <Controller
      control={control}
      label={label}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error }
      }) => (
        <DropdownCommon
          {...rest}
          error={error}
          label={label}
          onChange={onChange}
          renderOptions={renderOptions(options)}
          value={value}
        />
      )}
    />
  )
}

DropdownCreneaux.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
}

export default DropdownCreneaux
