import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Campaigns, Campus, Consentement, ConsentementMultiple, MentionsLegales, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber, RadioButton } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { campaigns, consent, consentement, defaultValues, getCampus, salutations, specialities } from "services"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar, setSnackMessage, setSnackSeverity } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...campaigns,
      ...consent,
      lead: {
        ...defaultValues,
        ...specialities.lead,
        ...consentement,
        Salutation: '',
        Section__c: '',
      }
    },
    resolver: yupResolver(schema)
  })
  const { control } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId
    const campus = lead.Campus__c
    const slotId = data.slotId
    const places = data.accompagnateurs || 0
    // Si on a choisi un créneau, on vérifie si le créneau est toujours dispo
    if (slotId !== '') {
      const slot = await salesforce.checkSlot(slotId, places)
      if (slot.length === 0) {
        setOpenSnackbar(true)
        setSnackMessage('Désolé les dernières places ont été prises. Veuillez choisir une autre date ou un autre créneau')
        setSnackSeverity('warning')
        return
      }
    }
    const defaultDataLead = {
      Division__c: `Sup'Biotech`,
      IONISSchool__c: `Sup'Biotech`,
      LastForm__c: 'JPO',
      LeadSource: 'JPO',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      if (campaignId !== 'unavailable') {
        const campaignMember = {
          CampaignId: campaignId,
          LeadId: leadCreated.Id,
          Status: 'Registered'
        }
        if (slotId !== '') {
          campaignMember.EventSlot__c = slotId
          campaignMember.NumberOfPerson__c = places
        }
        await salesforce.createCampaignMember(campaignMember)
        document.location.href=`https://www.supbiotech.fr/venir-nous-rencontrer/retour-journees-portes-ouvertes/?campus=${campus}&campaignId=${campaignId}`
      } else {
        let description = ''

        description += data.availabilities.dates.join(', ')

      const subject = data.availabilities.type
        const task = {
          ActivityDate: new Date(),
          Description: description,
          OwnerId: leadCreated.OwnerId,
          Subject: subject,
          WhoId: leadCreated.Id
        }

        await salesforce.createTask(task)
        document.location.href=`?campus=${campus}`
      }
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  // useEffect
  useSalesforce('EngineeringSchoolLead')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioButton control={control} label="Civilité" name="lead.Salutation" options={salutations} required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <AcademicLevel school="Sup'Biotech" />
        <Campus label="Campus souhaité" options={getCampus(`Sup'Biotech`)} />
        <Campaigns contact={false} division="Sup'Biotech" school="Sup'Biotech" text="à la Journée Portes Ouvertes" type="JPO" />
        <ConsentementMultiple />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
        <MentionsLegales form="JPO" school="Sup'Biotech" />
      </Grid>
    </FormProvider>
  )
}

export default Form
