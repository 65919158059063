import moment from "moment-timezone"
import "moment/locale/fr"

const formatHour = (dateStart, dateEnd, start, end, eda) => {
  if (start !== null && end === null) {
    return "à " + (eda ? moment(dateStart + " " + start).tz("Greenwich").format("HH[h]mm") : start)
  } else if (start !== null && end !== null) {
    const startFormatted = eda ? moment(dateStart + " " + start).tz("Greenwich").format("HH[h]mm") : start
    const endFormatted = eda ? moment(dateEnd + " " + end).tz("Greenwich").format("HH[h]mm") : end
    return "de " + startFormatted + " à " + endFormatted
  }
  return ""
}

const formatType = (isOnline) => isOnline ? " - En distanciel" : " - En présentiel"

const formatDate = (start, end, horaires) => {
  const formatDay = (date) => moment(date).date() === 1 ? "1er" : moment(date).format("D")
  const monthStart = moment(start).format("MMMM") !== moment(end).format("MMMM") ? moment(start).format("MMMM") : ""
  if (start !== end) {
    return " du " + moment(start).format("dddd") + " " + formatDay(start) + " " + monthStart + " au " + moment(end).format("dddd D MMMM YYYY") + " " + horaires
  } else {
    return " le " + moment(start).format("dddd") + " " + formatDay(start) + " " + moment(start).format("MMMM YYYY") + " " + horaires
  }
}

export const formatEvent = (events, contact = true, eda = false) => {
  let campaigns = events.map(event => {
    const label = event.Label__c || ""
    const startDate = event.StartDate
    const endDate = event.EndDate
    const startTime = event.EventStartHour__c
    const endTime = event.EventEndHour__c
    const horaires = formatHour(startDate, endDate, startTime, endTime, eda)
    const type = formatType(event.IsOnline__c)
    const dateDuration = formatDate(startDate, endDate, horaires)

    return {
      label: label + dateDuration + type,
      value: event.Id,
      date: moment(event.StartDate).format("dddd D MMMM YYYY"),
      dateDuration: dateDuration,
    }
  })

  if (contact) {
    campaigns.push({
      label: "Je ne suis pas disponible à ces dates",
      value: "unavailable",
    })
  }

  return campaigns
}
