import { Grid, Typography } from "@mui/material"

const RequiredFields = () => {
  return (
    <Grid item xs={12}>
      <Typography variant="caption">
        <sup>*</sup> Champs obligatoires
      </Typography>
    </Grid>
  )
}

export default RequiredFields
