import PropTypes from "prop-types"
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Typography } from "@mui/material"
import { Controller } from "react-hook-form"

const InputCheckbox = ({ control, label, name, ...rest }) => {
  return (
    <Controller
      control={control}
      defaultValue={false}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={<Checkbox {...field} size="small" />}
            label={
              <Typography variant="body2">
                {label}
              </Typography>
            }
          />
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

InputCheckbox.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  name: PropTypes.string.isRequired
}

export default InputCheckbox
