import { CssBaseline,  } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { default as Contact } from "./Contact/Form"
import { default as Documentation } from "./Documentation/Form"
import { default as GuideMetiers } from "./GuideMetiers/Form"
import { default as JourneeImmersion } from "./JourneeImmersion/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import { default as PrintempsOrientation } from "./PrintempsOrientation/Form"
import { default as SoireeSpeciale } from "./SoireeSpeciale/Form"
import theme from "./Theme/index"
import { FormContextProvider } from "hooks/useForm"
import Forms from "../Forms"

const ContactISEG = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Contact />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const DocumentationISEG = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const GuideMetiersISEG = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <GuideMetiers />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneeImmersionISEG = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneeImmersion />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesPortesOuvertesISEG = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneesPortesOuvertes />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const PrintempsOrientationISEG = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <PrintempsOrientation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const SoireeSpecialeISEG = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <SoireeSpeciale />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

export { ContactISEG, DocumentationISEG, GuideMetiersISEG, JourneeImmersionISEG, JourneesPortesOuvertesISEG, PrintempsOrientationISEG, SoireeSpecialeISEG }
