import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { FormHelperText, Grid } from "@mui/material"
import { AcademicLevel, Consentement, ConsentementMultiple, Departments, RequiredFields, Submit } from "components"
import { DropDownCheck, Email, InputText, PhoneNumber, RadioButton } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { consent, consentement, defaultValues, salutations } from "services"
import MentionsLegales from "components/MentionsLegales"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...consent,
      schools: [],
      lead: {
        ...defaultValues,
        ...consentement,
        Salutation: '',
        SchoolsInterestedBy__c: []
      }
    },
    resolver: yupResolver(schema)
  })
  const { control } = methods
  // State
  const schools = [
    { label: 'EPITA', value: 'EPITA' },
    { label: 'ESME', value: 'ESME' },
    { label: 'IPSA', value: 'IPSA' },
    { label: 'SupBiotech', value: 'SupBiotech' }
  ]
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const schools = data.schools.sort().join(',')
    const defaultDataLead = {
      Division__c: 'Inge',
      IONISSchool__c: 'Inge',
      LastForm__c: 'Demande de doc Advance',
      LeadSource: 'Internet: Demande de doc',
      SchoolsInterestedBy__c: schools,
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      await salesforce.createLead(finalLead)
      // document.location.href=`htts://www.concours-advance.fr/`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  // useEffect
  useSalesforce('Advance')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DropDownCheck control={control} label="Le.s école.s d’intérêt.s" name="schools" options={schools} required />
          <FormHelperText component="span" sx={{ display: 'block' }}>Je ne sais pas encore ? Alors je coche les 4 écoles</FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <RadioButton control={control} label="Civilité" name="lead.Salutation" options={salutations} required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" />
        </Grid>
        <Departments label="Département de résidence" />
        <AcademicLevel school="Inge" section={false} />
        <ConsentementMultiple />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
        <MentionsLegales form="Documentation" school="Advance" />
      </Grid>
    </FormProvider>
  )
}

export default Form
