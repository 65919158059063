import { createTheme } from "@mui/material/styles"
import { defaultTheme } from "components/Theme/Style"

const customTheme = (primaryColor = '#FFF') => {
  let themeOptions = {
    palette: { mode: 'light', primary: { main: '#102B65' }, secondary: { main: '#ff4d4d' }, neutral: { main: '#5c6ac4' } }
  }

  if (primaryColor !== '#FFF') {
    themeOptions = {
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: primaryColor
            }
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ffffff', // Couleur de la bordure
                }
              },
              '& .MuiInputLabel-root': {
                color: '#ffffff', // Couleur du label
              }
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            icon: {
              color: '#ffffff', // Couleur de l'icone
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: '#ffffff', // Couleur de la bordure
            }
          }
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              color: '#ffffff', // Couleur du label
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              color: '#ffffff', // Couleur du label
            },
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              color: '#ffffff', // Couleur du label
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              color: '#102B65', // Couleur du label
            },
          },
        }
      },
      palette: {
        mode: 'light', primary: { main: '#FFF', dark: '#FFF' },
        secondary: { main: '#ff4d4d' }, neutral: { main: '#5c6ac4' },
        text: { primary: '#FFF' }, error: { main: '#FF5733' }
      }
    }
  }

  return createTheme(defaultTheme, themeOptions)
}

export { customTheme }


