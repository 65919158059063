import PropTypes from "prop-types"
import { TextField } from "@mui/material"
import { Controller } from "react-hook-form"

const InputText = ({ control, label, name, ...rest }) => {
  const { required } = rest

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState
      }) => (
        <TextField
          {...rest}
          aria-label={label}
          error={!!error}
          fullWidth
          helperText={error ? error.message : null}
          id={name}
          inputProps={{
            autoComplete: 'new-password',
          }}
          label={label}
          name={name}
          onChange={onChange}
          required={required}
          size="small"
          value={value}
          variant="outlined"
        />
      )}
      rules={rest}
    />
  )
}

InputText.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default InputText
