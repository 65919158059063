import * as Yup from "yup"
import { AcademicLevel, Availabilities, CampaignId, Campus, Email, FirstName, LastName, MobilePhoneRequired, PostalCode, Salutation } from "services/validation"

const schema = Yup.object().shape({
  ...Availabilities,
  ...CampaignId,
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhoneRequired,
    ...PostalCode,
    ...Salutation
  })
})

export default schema
