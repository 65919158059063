import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Campus, Consentement, Cursus, Disponibilites, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { APPOINTMENT, CALL_PHONE, campaigns, defaultValues, getCampus } from "services"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...campaigns,
      campaignId: 'unavailable',
      lead: {
        ...defaultValues,
        Salutation: '',
        Section__c: '',
        Wished_Programm__c: ''
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, watch } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const defaultDataLead = {
      LeadSource: 'Demande de rendez-vous',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)

      const subject = data.availabilities.type
      const task = {
        ActivityDate: new Date(),
        OwnerId: leadCreated.OwnerId,
        Subject: subject,
        WhoId: leadCreated.Id
      }

      await salesforce.createTask(task)
      document.location.href=`https://www.e-artsup.net/retour-demande-entretien/?ville=${campus}`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const campus = watch('lead.Campus__c')
  // useEffect
  useSalesforce('BusinessSchoolLead')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <AcademicLevel school="e-artsup" />
        <Campus label="Campus souhaité" options={getCampus('e-artsup')} />
        <Cursus ecole="e-artsup" label="Programme d'intérêt" />
        <Disponibilites  availabilities={[{ label: CALL_PHONE, value: CALL_PHONE }, { label: APPOINTMENT , value: APPOINTMENT }]} eda={false} wantavailabilities={false} />
        <Consentement form="Contact" school="e-artsup" />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
      </Grid>
    </FormProvider>
  )
}

export default Form
