import PropTypes from "prop-types"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Campus, Consentement, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { defaultValues } from "services"
import { useEffect, useState } from "react"

const Form = ({ campus, msc }) => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      campaignId: '',
      lead: {
        ...defaultValues,
        LastForm__c: 'Contact',
        Salutation: '',
        Section__c: '',
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, setValue, watch } = methods
  // State
  const [campaigns, setCampaigns] = useState([])
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId
    const campus = data.lead.Campus__c
    const defaultDataLead = {
      Division__c: 'ISG France',
      IONISSchool__c: 'ISG',
      LastForm__c: 'Contact',
      LeadSource: 'Internet: demande de contact',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)

      if (campaignId !== '') {
        const campaignMember = {
          CampaignId: campaignId,
          LeadId: leadCreated.Id,
          Status: 'Contact Requested'
        }
        await salesforce.createCampaignMember(campaignMember)
      }

      const subject = 'Ce prospect souhaite être recontacté'
      const task = {
        ActivityDate: new Date(),
        OwnerId: leadCreated.OwnerId,
        Subject: subject,
        WhoId: leadCreated.Id
      }

      await salesforce.createTask(task)
      const isMsc = msc !== undefined ? `&msc=${msc}` : ''
      document.location.href=`${process.env.REACT_APP_ISG_URL}/entretien-personnalise/retour-entretien-personnalise/?ville=${campus}${isMsc}`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const campusWatch = watch('lead.Campus__c')
  const finalCampus = campus !== undefined ? campus : campusWatch
  // useEffect
  useEffect(() => {
    if (salesforce !== null) {
      const getData = async () => {
        const campaigns = await salesforce.getCampaigns('ISG France', 'ISG', 'Demande de contact')

        return { campaigns }
      }

      getData().then((value) => {
        const { campaigns } = value
        setCampaigns(campaigns)
        // setOpenBackdrop(false)
      }).catch((error) => {
        setError('Une erreur est survenue lors du chargement du formulaire', error)
      })
    }
  }, [salesforce, setError])

  useSalesforce('ISG')

  useEffect(() => {
    if (finalCampus !== '') {
      const campaign = campaigns.find(el => el.Campus__c === finalCampus)
      if (campaign !== undefined) {
        setValue('campaignId', campaign.Id)
        setValue('lead.Campus__c', finalCampus)
      }
    }
  }, [campaigns, finalCampus, setValue])

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <Grid item sm={campus === undefined ? 6 : 12} xs={12}>
          <AcademicLevel section={false} specialites={false} />
        </Grid>
        {campus === undefined && (
          <Grid item sm={6} xs={12}>
            <Campus label="Campus souhaité" />
          </Grid>
        )}
        <Consentement form="JPO" school="ISG France" />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
      </Grid>
    </FormProvider>
  )
}

Form.propTypes = {
  campus: PropTypes.string,
  msc: PropTypes.string
}

export default Form
