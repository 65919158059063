import { DevTool } from "@hookform/devtools"
import { Grid, Paper } from "@mui/material"
import Adresse from "components/Adresse"
import { FormProvider, useForm } from "react-hook-form"

const DemoAdresse = () => {

  const methods = useForm({
    defaultValues: {
      lead: {
        City: '',
        Country: '',
        PostalCode: '',
        Street: ''
      }
    }
  })

  const { control } = methods
  const countries = [{ label: 'France', value: 'France' }]

  return (
    <>
      <DevTool control={control} />
      <FormProvider {...methods}>
        <Paper sx={{ m: 2, p: 5 }}>
          <Grid container spacing={2}>
            <Adresse countries={countries} />
          </Grid>
        </Paper>
      </FormProvider>
    </>
  )
}

export default DemoAdresse
