import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { findFormations } from "../services/functions"
import formations from "../services/formations.json"
import { Grid } from "@mui/material"
import DropDownCheck from "components/fields/DropdownCheck"

const Formations = () => {

  const { control, setValue, watch } =useFormContext()

  const academicLevel = watch('lead.AcademicLevel__c')
  const watchFormations = watch('formations')
  const [optionsFormations, setOptionsFormations] = useState([])
  const showAcademics = ['Bac +2', 'Bac +3', 'Bac +4', 'Bac +5', 'Autre']

  useEffect(() => {
    if (academicLevel !== '') {
      setValue('formations', [])
      const formationsByAcademicLevel = findFormations(academicLevel, formations)
      setOptionsFormations(formationsByAcademicLevel)
      if (formationsByAcademicLevel.length === 1) {
        setValue(formationsByAcademicLevel[0].field, true)
        setValue('formations', [formationsByAcademicLevel[0].value])
      }
    }
  }, [academicLevel])

  useEffect(() => {
    for (const options of formations) {
      setValue(options.field, false)
    }
    if (watchFormations.length > 0) {
      const mapFormations = optionsFormations.filter(el => watchFormations.some(el2 => el.label === el2)).map(el => el.field)
      for (const formation of mapFormations) {
        setValue(formation, true)
      }
    }
  }, [watchFormations])

  return (
    <>
      {optionsFormations.length > 1 && (
        <Grid item xs={12}>
          <DropDownCheck control={control} label="Cursus d'intérêt" multiple name="formations" options={optionsFormations} required />
        </Grid>
      )}
    </>
  )
}

export default Formations
