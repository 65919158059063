import { createTheme } from "@mui/material/styles"
import { defaultTheme } from "components/Theme/Style"

const customTheme = (color = '#FFF', backgroundColor='#000000', hover="#FFee") => {
    let themeOptions = {
        palette: {
            mode: 'light',
            primary: { main: '#102B65' },
            secondary: { main: '#ff4d4d' },
            neutral: { main: '#5c6ac4' }
        }
    }

    if (color !== '#FFF') {
        themeOptions = {
            components: {
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            backgroundColor: backgroundColor
                        }
                    }
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: hover, // Couleur de la bordure
                                }
                            },
                            '& .MuiInputLabel-root': {
                                color: color, // Couleur du label 
                            }
                        },
                    },
                },
                MuiSelect: {
                    styleOverrides: {
                        icon: {
                            color: color, // Couleur de l'icone 
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
                            borderColor: hover, // Couleur de la bordure 
                        }
                    }
                },
                MuiFormLabel: {
                    styleOverrides: {
                        root: {
                            color: color, // Couleur du label 
                        },
                    },
                },
                MuiCheckbox: {
                    styleOverrides: {
                        root: {
                            color: color, // Couleur de l'icône 
                        },
                    },
                },
                MuiRadio: {
                    styleOverrides: {
                        root: {
                            color: color, // Couleur de l'icône 
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            color: backgroundColor, // Couleur du texte 
                            
                        },
                    },
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            color:color, //color helpertext
                        }
                    }  
                }
            },
            palette: {
                mode: 'light',
                primary: { main: color, dark: color },
                secondary: { main: '#ff0000' },
                neutral: { main: '#5c6ac4' },
                text: { primary: color }, 
                error: { main: '#FF5733' }
            }
        }
    }

    return createTheme(defaultTheme, themeOptions)
}

export { customTheme }

//librairie color 