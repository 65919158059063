export function findFormations(campus, formations, isMultiple = false) {
  return formations.filter(formation => formation.campus.includes(campus)).map(el => ({
    field: el.field,
    label: el.formation + (isMultiple && el.campus.length === 1 ? ` (uniquement disponible sur le campus de ${el.campus[0]})` : ''),
    value: el.formation
  }))
}

export function constructParams(formationsChoisies, formations) {
  const formationsParams = formations.filter(el => formationsChoisies.includes(el.formation)).map(el => '&programme[]=' + el.param)
  return formationsParams.join('')
}
