import PropTypes from "prop-types"
import { MuiTelInput } from "mui-tel-input"
import { Controller } from "react-hook-form"

const PhoneNumber = ({ control, defaultCountry = "FR", label, name, required, ...rest }) => {

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTelInput
          {...field}
          {...rest}
          defaultCountry={defaultCountry}
          error={!!error}
          fullWidth
          helperText={error ? error.message : null}
          id={name}
          label={label}
          langOfCountryName="FR"
          MenuProps={{ disablePortal: true }}
          preferredCountries={["FR", "BE", "CH"]}
          required={required}
          size="small"
        />
      )}
    />
  )
}

PhoneNumber.propTypes = {
  control: PropTypes.object.isRequired,
  defaultCountry: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool
}

export default PhoneNumber
