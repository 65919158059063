import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import ReactDOM from "react-dom"
// Crée un composant web personnalisé pour chaque composant de formulaire
export default function defineWebComponent(tag, Component) {
  class WebComponent extends HTMLElement {
    connectedCallback() {
      const shadowContainer = this.attachShadow({ mode: 'closed' })
      const shadowRootElement = document.createElement('div')
      shadowContainer.appendChild(shadowRootElement)

      const props = {}
      for (const attribute of this.attributes) {
        const { name, value } = attribute
        props[name] = value
      }

      const cache = createCache({
        key: 'css',
        prepend: true,
        container: shadowContainer,
      })
      ReactDOM.render(<CacheProvider value={cache}><Component {...props} /></CacheProvider>, shadowRootElement)
    }
  }
  customElements.define(tag, WebComponent)
}
