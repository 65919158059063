import PropTypes from "prop-types"
import { Alert, Grid } from "@mui/material"
import { useFormContext } from "react-hook-form"
import { APPOINTMENT, CALL_PHONE, CALL_SKYPE, CALL_WHATSAPP, optionsAvailabilities, optionsDates } from "services/disponibilites"
import Dropdown from "components/fields/Dropdown"
import DropDownCheck from "components/fields/DropdownCheck"
import InputText from "components/fields/InputText"
import { useEffect, useState } from "react"

const Disponibilites = ({ availabilities, contact = true, eda = false, entretien = true, lastform = true, text, skype = true, wantavailabilities = true, whatsapp = false }) => {

  const { control, setValue, watch } = useFormContext()
  const availabilitiesType = watch('availabilities.type')
  const campaignId = watch('campaignId')
  const [dispos, setDispos] = useState([])

  useEffect(() => {
    if (!eda && lastform) {
      if (availabilitiesType.includes('téléphone')) {
        setValue('lead.LastForm__c', 'Contact Téléphone')
      }
      if (availabilitiesType.includes('Skype')) {
        setValue('lead.LastForm__c', 'Contact Skype')
      }
      if (availabilitiesType.includes('entretien')) {
        setValue('lead.LastForm__c', 'Contact Entretien')
      }
    }
  }, [availabilitiesType])

  useEffect(() => {
    if (availabilities !== undefined && availabilities.length === 1 && campaignId === 'unavailable') {
      setValue('availabilities.type', availabilities[0].label)
    } else {
      setValue('availabilities.type', '')
    }
  }, [campaignId])

  useEffect(() => {
    let disponibilites = []
    if (contact) {
      disponibilites.push({
        label: CALL_PHONE,
        value: CALL_PHONE
      })
    }
    if (skype) {
      disponibilites.push({
        label: CALL_SKYPE,
        value: CALL_SKYPE
      })
    }
    if (whatsapp) {
      disponibilites.push({
        label: CALL_WHATSAPP,
        value: CALL_WHATSAPP
      })
    }
    if (entretien) {
      disponibilites.push({
        label: APPOINTMENT,
        value: APPOINTMENT
      })
    }

    setDispos(disponibilites)
  }, [])

  return (
    <>
      {(campaignId === 'unavailable') && (
        <>
          {campaignId === 'unavailable' && text !== undefined && (<Grid item xs={12}>
            <Alert color="info">
              S'il n'y a plus de dates pour {text} ou si vous n'êtes pas disponible aux dates proposées, vous avez la possibilité d'être recontacté.
            </Alert>
          </Grid>
          )}
          <Grid item xs={12}>
            <Dropdown control={control} label="Je souhaite" name="availabilities.type" options={availabilities || dispos || optionsAvailabilities} required />
          </Grid>
          {availabilitiesType !== '' && wantavailabilities && (
            <Grid item xs={12}>
              <DropDownCheck control={control} label="Je suis disponible aux dates suivantes" name="availabilities.dates" options={optionsDates} required />
            </Grid>
          )}
          {availabilitiesType.includes('Skype') && (
            <Grid item xs={12}>
              <InputText control={control} label="Identifiant Skype" name="availabilities.skypeId" required />
            </Grid>
          )}
        </>
      )}
    </>
  )
}

Disponibilites.propTypes = {
  availabilities: PropTypes.array,
  contact: PropTypes.bool,
  eda: PropTypes.bool,
  entretien: PropTypes.bool,
  lastform: PropTypes.bool,
  text: PropTypes.string,
  wantavailabilities: PropTypes.bool,
  skype: PropTypes.bool,
  whatsapp: PropTypes.bool
}

export default Disponibilites
