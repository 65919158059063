import PropTypes from "prop-types"
import SalesforceEda from "services/salesforce"

const { createContext, useContext, useState, useEffect, useCallback, useMemo } = require("react")

const FormContext = createContext({
  fetched: false
})

export function useForms() {
  return useContext(FormContext)
}

export function FormContextProvider ({ children }) {

  const [componentStates, setComponentStates] = useState([])
  const [fetched, setFetched] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [snackSeverity, setSnackSeverity] = useState('error')
  const [recordTypeId, setRecordTypeId] = useState('')
  const [salesforce, setSalesforce] = useState(null)

  const componentFetched = (componentName) => {
    setComponentStates((prevState) => {
      const updatedStates = prevState.map((component) =>
        component.name === componentName ? { ...component, fetched: true } : component
      )
      return updatedStates
    })
  }

  const fetchRecordTypeId =  useCallback(async (school) => {
    if (salesforce !== null) {
     try {
        const recordTypeId = await salesforce.getRecordTypeId(school)
        setRecordTypeId(recordTypeId)
      } catch (error) {
        setError('Une erreur est survenue lors du chargement du formulaire', error)
      }
    }
  }, [salesforce])

  const fetchSalesforce = useCallback((eda) => {
    const salesforce = new SalesforceEda({ eda: eda })
    setSalesforce(salesforce)
  }, [])

  const setError = (message, error) => {
    setOpenSnackbar(true)
    let errorMessage = message
    if (process.env.REACT_APP_ENVIRONMENT !== 'main') {
      errorMessage = errorMessage + ' ' + error
    }

    setSnackMessage(errorMessage)
    setSnackSeverity('error')
  }

  useEffect(() => {
    const fetched = componentStates.every((component) => component.added && component.fetched)
    setFetched(fetched)
  }, [componentStates])

  const values = useMemo(() => ({
    componentFetched,
    componentStates,
    fetched,
    fetchRecordTypeId,
    fetchSalesforce,
    openSnackbar,
    recordTypeId,
    salesforce,
    setComponentStates,
    setError,
    setOpenSnackbar,
    setSnackMessage,
    setSnackSeverity,
    snackMessage,
    snackSeverity
  }), [componentStates, fetchRecordTypeId, fetchSalesforce, fetched, openSnackbar, recordTypeId, salesforce, snackMessage, snackSeverity])

  return <FormContext.Provider value={values}>
    {children}
  </FormContext.Provider>
}

FormContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
