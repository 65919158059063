import PropTypes from 'prop-types'
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel } from "@mui/material"
import { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"

const CheckboxMultiple = ({
  control,
  label,
  name,
  options
}) => {

  const [selectedItems, setSelectedItems] = useState([])

  const { setValue } = useFormContext()

  const handleSelect = (value) => {
    const isPresent = selectedItems.indexOf(value)
    if (isPresent !== -1) {
      const remaining = selectedItems.filter(item => item !== value)
      setSelectedItems(remaining)
    } else {
      setSelectedItems(prevItems => [...prevItems, value])
    }
  }

  useEffect(() => {
    setValue(name, selectedItems)
  }, [setValue, selectedItems])

  return (
    <Controller
      control={control}
      name={name}
      render={({
        fieldState: { error }
      }) => (
        <FormControl error={!!error}>
          <FormLabel error={!!error}>{label}</FormLabel>
          <div>
            {options.map((option) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedItems.includes(option.value)}
                      onChange={() => handleSelect(option.value)}
                      size="small"
                    />
                  }
                  key={option.value}
                  label={option.label}
                />
              )
            })}
          </div>
          <FormHelperText>{error !== undefined ? error.message : ''}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

CheckboxMultiple.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
}

export default CheckboxMultiple
