import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import specialites from "../../services/specialites"
import DropDownCheck from "../fields/DropdownCheck"
import { usePrevious } from "hooks/usePrevious"

const Specialites = () => {

  const { control, setValue, watch } = useFormContext()

  const watchSpecialites = watch('specialites')
  const watchAcademicLevel = watch('lead.AcademicLevel__c')
  const watchSection = watch('lead.Section__c')
  const previousAcademicLevel = usePrevious(watchAcademicLevel)
  const previousSection = usePrevious(watchSection)
  const [nombreSpecialites, setNombreSpecialites] = useState('')

  useEffect(() => {
    if ((watchAcademicLevel !== previousAcademicLevel) || (watchSection !== previousSection)) {
      setValue('specialites', [])
    }
    const academic = watchAcademicLevel === "Terminale (BAC)" ? "terminale" : "premiere"
    if (academic === 'terminale') {
      setNombreSpecialites('deux')
    }
    if (academic === 'premiere') {
      setNombreSpecialites('trois')
    }

    for (const specialite of specialites) {
      setValue(specialite.premiere, false)
      setValue(specialite.terminale, false)
    }

    if (watchSpecialites !== undefined) {
      const mapSpecialites = specialites.filter(el => watchSpecialites.some(el2 => el.label === el2)).map(el => el[academic])

      for (const specialite of mapSpecialites) {
        setValue(specialite, true)
      }
    }
  }, [previousAcademicLevel, previousSection, setValue, watchAcademicLevel, watchSection, watchSpecialites])

  return (
    <>
      {((watchAcademicLevel === "Première" || watchAcademicLevel === "Terminale (BAC)") && watchSection === "Générale") && (
        <Grid item xs={12}>
          <DropDownCheck control={control} disabledValuesAfter={true} label={`Mes ${nombreSpecialites} spécialités en ${watchAcademicLevel} sont`} multiple name="specialites" options={specialites} required />
        </Grid>
      )}
    </>
  )
}

export default Specialites
