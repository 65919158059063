import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Adresse, Campus, ChoixDocumentation, ConsentementMultiple, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { adresse, consent, consentement, defaultValues, getCampus } from "services"
import MentionsLegales from "components/MentionsLegales"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      choix: '',
      ...consent,
      lead: {
        ...defaultValues,
        ...adresse,
        ...consentement
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, watch } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campus = lead.Campus__c
    const choix = data.choix
    const defaultDataLead = {
      Division__c: 'ISEG MCS',
      IONISSchool__c: 'ISEG MCS',
      LastForm__c: 'Documentation PDF',
      LeadSource: 'Internet: Demande de doc',
      Paper_doc_request__c: choix === 'recevoir',
      PDFdownloaded__c: choix === 'telecharger',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      await salesforce.createLead(finalLead)
      const page = choix === 'telecharger' ? 'telechargement-retour-ok' : 'documentation-retour-ok'
      document.location.href=`${process.env.REACT_APP_ISEG_URL}/documentation-ecole-communication/${page}/?ville=${campus}&niveau=${encodeURIComponent(lead.AcademicLevel__c)}`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const choix = watch('choix')
  // useEffect
  useSalesforce('BusinessSchoolLead')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <ChoixDocumentation />
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <AcademicLevel school="ISEG MCS" section={false} specialites={false} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Campus label="Campus souhaité" options={getCampus('ISEG MCS')} />
        </Grid>
        {choix === 'recevoir' && (
          <Adresse />
        )}
        <ConsentementMultiple />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
        <MentionsLegales form="Documentation" school="ISEG MCS" />
      </Grid>
    </FormProvider>
  )
}

export default Form
