import { Alert, Link } from "@mui/material";
import schools from "./schools";

/**
 * Returns a JSX element representing the consent message based on the provided form and division.
 * If the consent information is not available, returns an Alert component indicating a problem with retrieving consent.
 *
 * @param {string} form - The type of form, either 'Documentation' or any other value.
 * @param {string} division - The division of the school.
 * @returns {JSX.Element|Alert} - A JSX element representing the consent message or an Alert component.
 */
const labelConsentement = (form, division) => {
  const { domain, name, url } =
    schools.find((el) => el.division === division) || {};

  if (domain) {
    return (
      <span className="consent">
        {name} enregistre et traite les données personnelles que vous nous fournissez afin de répondre à votre demande, vous contacter pour discuter de celle-ci, vous communiquer des informations sur les programmes et vous inviter à des événements de l'école. En vertu de la Loi Informatique et Libertés et du RGPD, vous disposez d'un droit d'accès, de rectification, de suppression et d'opposition. Vous pouvez exercer ce droit auprès de notre DPO : <Link href={`mailto:dpo@${domain}`}>dpo@{domain}</Link> et/ou via le lien de désabonnement compris dans les communications concernées. Pour plus d'informations, nous vous invitons à consulter notre <Link href={url} target="_blank">Politique de confidentialité</Link>.
      </span>
    )
  } else {
    return (
      <Alert severity="error">
        Problème de récupération au niveau du consentement
      </Alert>
    );
  }
};

export { labelConsentement };
