import { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { Grid } from "@mui/material"
import DropDownCheck from "components/fields/DropdownCheck"
import { getCampus } from "services/campus"

const Campus = () => {

  const { control, setValue, watch } =useFormContext()

  const watchCampus = watch('campus')

  useEffect(() => {
    setValue('lead.Campus__c', watchCampus.sort().join(','))
  }, [watchCampus])

  return (
    <Grid item xs={12}>
      <DropDownCheck control={control} label="Campus souhaité(s)" multiple name="campus" options={getCampus('IPSA')} required />
    </Grid>
  )
}

export default Campus
