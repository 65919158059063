import { createTheme } from "@mui/material/styles";
import { defaultTheme } from "components/Theme/Style";

const theme = createTheme(defaultTheme, {
  palette: {
    mode: "light",
    primary: { main: "#555555", dark: "#3b3b3b" },
    secondary: { main: "#ff4d4d" },
    neutral: { main: "#5c6ac4" },
  },
});

export default theme;
