const adresse = {
  City: '',
  Country: '',
  PostalCode: '',
  Street: ''
}

const campaigns = {
  accompagnateurs: '',
  availabilities: {
    dates: [],
    skypeId: '',
    type: ''
  },
  campaignId: '',
  slotId: ''
}

const consent = {
  consent: '',
  consentement: []
}

const consentement = {
  OptIn_Email__c: false,
  OptIn_SMS__c: false,
  OptIn_WhatsApp__c: false
}

const defaultValues = {
  AcademicLevel__c: '',
  Campus__c: '',
  Email: '',
  FirstName: '',
  LastName: '',
  MarketingOptIn__c: '',
  MobilePhone: ''
}

const specialities = {
  lead: {
    PremGEnsSpeMaths__c: false,
    PremGEnsSpeSciencesInge__c: false,
    PremGEnsSpePhysiqueChimie__c: false,
    PremGEnsSpeNumSciencesInfo__c: false,
    PremGEnsSpeSVT__c: false,
    PremGEnsSpeBioEcologie__c: false,
    PremGEnsSpeArts__c: false,
    PremGEnsSpeHumaniteLitteraturePhilo__c: false,
    PremGEnsSpeLittelanguesCultAntiq__c: false,
    PremGEnsSpeLanguesLitteCultEtr__c: false,
    PremGEnsSpeSciencesEco__c: false,
    PremGEnsSpeHistGeoSciencesPo__c: false,
    TermGEnsSpeMaths__c: false,
    TermGEnsSpeSciencesInge__c: false,
    TermGEnsSpePhysiqueChimie__c: false,
    TermGEnsSpeNumSciencesInfo__c: false,
    TermGEnsSpeSVT__c: false,
    TermGEnsSpeBioEcologie__c: false,
    TermGEnsSpeArts__c: false,
    TermGEnsSpeHumaniteLitteraturePhilo__c: false,
    TermGEnsSpeLittelanguesCultAntiq__c: false,
    TermGEnsSpeLanguesLitteCultEtr__c: false,
    TermGEnsSpeSciencesEco__c: false,
    TermGEnsSpeHistGeoSciencesPo__c: false
  },
  specialites: []
}

export { adresse, campaigns, consent, consentement, defaultValues, specialities }
