import { AcademicLevel, Campus, Email, FirstName, LastName, MobilePhoneRequired, PostalCodeNotRequired } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  formations: Yup.array().min(1, `Vous devez choisir au minimum un cursus d'intérêt`),
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhoneRequired,
    ...PostalCodeNotRequired
  })
})

export default schema
