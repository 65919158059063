import PropTypes from "prop-types"
import { Alert, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import Dropdown from "components/fields/Dropdown"
import DropdownCreneaux from "components/fields/DropdownCreneaux"
import { useForms } from "hooks/useForm"
import { formatEvent } from "services"

const Creneaux = ({ campaigns }) => {

  // const salesforce = new SalesforceEda({ eda: false })
  const { control, setValue, watch } = useFormContext()
  const { salesforce } = useForms()
  const campaignId = watch('campaignId')
  const slotId = watch('slotId')
  const [optionsCreneaux, setOptionsCreneaux] = useState([])
  const [placesCreneaux, setPlacesCreneaux] = useState(0)
  const [optionsAccompagnateurs, setOptionsAccompagnateurs] = useState([])

  useEffect(() => {
    setValue('slotId', '')
    setValue('slots', [])
    setValue('maxAccompagnateurs', [])
    setValue('accompagnateurs', '')
    setOptionsCreneaux([])
    setOptionsAccompagnateurs([])
    if (campaignId !== 'unavailable' && campaignId !== '') {
      // On cherche si il y a des créneaux pour cette campagne
      const campaign = campaigns.find(el => el.Id === campaignId)
      if (campaign?.Slots__r !== null && campaign?.Slots__r !== undefined) {
        const creneaux = salesforce.formatCreneaux(campaign.Slots__r.records)
        const numberPlaces = creneaux.map(el => el.places).reduce((acc, value) => {
          return acc + value
        }, 0)
        setPlacesCreneaux(numberPlaces)
        setOptionsCreneaux(creneaux)
        setValue('slots', creneaux)
      }
      if (campaign.MaxNumberOfEventAttendants__c > 0) {
        const maxNumberOfEventAttendants = fillAccompagnateurs(campaign.MaxNumberOfEventAttendants__c)

        setOptionsAccompagnateurs(maxNumberOfEventAttendants)
        setValue('maxAccompagnateurs', maxNumberOfEventAttendants)
      }
    }
  }, [campaignId])

  useEffect(() => {
    setValue('accompagnateurs', '')
    if (campaignId !== '' && slotId !== '') {
      const campaign = campaigns.find(el => el.Id === campaignId)
      const creneau = campaign.Slots__r.records.find(el => el.Id === slotId)
      if (creneau !== undefined) {
        if (campaign.MaxNumberOfEventAttendants__c > 0) {
          const maxAttendants = creneau.RemainingPlaces__c <= campaign.MaxNumberOfEventAttendants__c ? creneau.RemainingPlaces__c : campaign.MaxNumberOfEventAttendants__c + 1
          const maxNumberOfEventAttendants = fillAccompagnateurs(maxAttendants)

          setOptionsAccompagnateurs(maxNumberOfEventAttendants)
          setValue('maxAccompagnateurs', maxNumberOfEventAttendants)
        }

        const _campaigns = formatEvent(campaigns)
        const _campaign = _campaigns.find(el => el.value === campaignId)
        const eventDateAndDuration = _campaign !== undefined ? _campaign.date : ''
        const horaireCreneaux = ' de ' + creneau.SlotStartTime__c + ' à ' + creneau.SlotEndTime__c

        setValue('lead.EventDateAndDuration__c', eventDateAndDuration + horaireCreneaux)
      }
    }
  }, [slotId])

  const fillAccompagnateurs = (max) => {
    let maxAttendants = []
    for (let i = 0; i < max; i++) {
      maxAttendants.push({
        label: i,
        value: i
      })
    }
    return maxAttendants
  }

  return (
    <>
      {optionsCreneaux.length > 0 && (
        <Grid item xs={12}>
          {placesCreneaux > 0 && (
            <DropdownCreneaux control={control} label="Choix du créneau" name="slotId" options={optionsCreneaux} required />
          )}
          {placesCreneaux <= 0 && (
            <Alert color="warning">
              Il y a actuellement plus de places disponibles pour cet événement.
            </Alert>
          )}
        </Grid>
      )}
      {optionsAccompagnateurs.length > 0 && (
        <Grid item xs={12}>
          <Dropdown control={control} label="Nombre d'accompagnateurs" name="accompagnateurs" options={optionsAccompagnateurs} required />
        </Grid>
      )}
    </>
  )
}

Creneaux.propTypes = {
  campaigns: PropTypes.array.isRequired
}

export default Creneaux
