import { AcademicLevel, Campus, Email, FirstName, LastName, MobilePhoneRequired } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  consent: Yup.string().required('Veuillez faire un choix'),
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhoneRequired
  })
})

export default schema
