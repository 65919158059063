import { Grid } from "@mui/material"
import { RadioButton } from "components/fields"
import { useFormContext } from "react-hook-form"
import { choixDocumentation } from "services"

const ChoixDocumentation = () => {

  const { control } = useFormContext()

  return (
    <Grid item xs={12}>
      <RadioButton control={control} label="Je souhaite" name="choix" options={choixDocumentation} required />
    </Grid>
  )
}

export default ChoixDocumentation
