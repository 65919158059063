import { AcademicLevel, Campus, Email, FirstName, LastName, MobilePhoneRequired, Section, Specialites, WishedProgramm } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhoneRequired,
    ...Section,
    ...WishedProgramm
  }),
  ...Specialites
})

export default schema
