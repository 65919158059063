import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Campaigns, Campus, Consentement, ConsentementMultiple, MentionsLegales, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { campaigns, consent, consentement, defaultValues, getCampus } from "services"
import Formations from "../components/Formations"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...campaigns,
      ...consent,
      formations: [],
      lead: {
        ...defaultValues,
        ...consentement,
        Apprentissage__c: false,
        Bachelor__c: false,
        CycleInge__c: false,
        IA_Institut__c: false,
        Master__c: false,
        Salutation: '',
        Section__c: ''
      },
    },
    resolver: yupResolver(schema)
  })
  const { control, watch } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId
    const defaultDataLead = {
      Division__c: 'EPITA',
      IONISSchool__c: 'EPITA',
      LastForm__c: 'Webinar',
      LeadSource: 'Webinar',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      if (campaignId !== 'unavailable') {
        const campaignMember = {
          CampaignId: campaignId,
          LeadId: leadCreated.Id,
          Status: data.status
        }
        await salesforce.createCampaignMember(campaignMember)
        document.location.href=`${process.env.REACT_APP_EPITA_URL}/live/inscription-validee/?ville=${campus}&campaignId=${campaignId}`
      }
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const campus = watch('lead.Campus__c')
  const campaignNational = watch('campaignNational')
  // useEffect
  useSalesforce('EngineeringSchoolLead')
  // Functions

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Campus label={!campaignNational ? `Ville choisie pour participer à l'événement virtuel` : `Campus d'intérêt`} options={getCampus('EPITA')} />
        <Campaigns contact={false} division="EPITA" school="EPITA" text={`à l'événement virtuel`} textAvailability="pour nos événements virtuels" type="Webinar" />
        <Formations />
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone mobile" required />
        </Grid>
        <AcademicLevel school="EPITA" section={true} specialites={true} />
        <ConsentementMultiple />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
        <MentionsLegales form="Webinar" school="EPITA" />
      </Grid>
    </FormProvider>
  )
}

export default Form
