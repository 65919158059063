import { useEffect } from "react"
import { useForms } from "./useForm"

export function useSalesforce(recordtypeId, eda = false) {

  const { fetchRecordTypeId, fetchSalesforce } = useForms()

  useEffect(() => {
    fetchSalesforce(eda)
  }, [eda, fetchSalesforce])

  useEffect(() => {
    fetchRecordTypeId(recordtypeId)
  }, [fetchRecordTypeId, recordtypeId])
}
