import { AcademicLevel, Email, FirstName, LastName, MobilePhoneRequired } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhoneRequired
  })
})

export default schema
