const formations = [
  {
    label: 'Direction Artistique',
    value: 'Direction artistique'
  },
  {
    label: 'Motion Design',
    value: 'Motion design'
  },
  {
    label: 'Animation 2D/3D',
    value: 'Animation'
  },
  {
    label: 'Game Design & jeux vidéo',
    value: 'Game'
  },
  {
    label: 'Je ne sais pas',
    value: 'Je ne sais pas'
  }
]

export default formations
