import { AcademicLevel, Accompagnateurs, Availabilities, CampaignId, Campus, Email, FirstName, LastName, MobilePhoneRequired, Salutation, Section, SlotId, Specialites } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  ...Accompagnateurs,
  ...Availabilities,
  ...CampaignId,
  consent: Yup.string().required('Veuillez faire un choix'),
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhoneRequired
  }),
  ...SlotId
})

export default schema
