import { CssBaseline,  } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { default as Documentation } from "./Documentation/Form"
import { default as JourneesDecouverte } from "./JourneesDecouverte/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import { default as RdvOrientation } from "./RdvOrientation/Form"
import theme from "./Theme/index"
import { FormContextProvider } from "hooks/useForm"
import Forms from "../Forms"

const DocumentationESME = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesDecouverteESME = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneesDecouverte />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesPortesOuvertesESME = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneesPortesOuvertes />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const RdvOrientationESME = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <RdvOrientation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

export { DocumentationESME, JourneesDecouverteESME, JourneesPortesOuvertesESME, RdvOrientationESME }
