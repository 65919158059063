const Cookie = () => {

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  return (
    <>La valeur du cookie _ga est : {getCookie('_ga')}</>
  )

}

export default Cookie
