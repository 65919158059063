import PropTypes from "prop-types"
import { Grid } from "@mui/material"
import Dropdown from "components/fields/Dropdown"
import { useForms } from "hooks/useForm"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

const Cursus = ({ ecole, label }) => {

  const { control } = useFormContext()
  const { componentFetched, recordTypeId, salesforce, setComponentStates, setOpenSnackbar, setSnackMessage } = useForms()
  const [optionsCursus, setOptionsCursus] = useState([])

  useEffect(() => {
    setComponentStates((prevState) => [
      ...prevState,
      { name: 'cursus', added: true, fetched: false },
    ])
  }, [setComponentStates])

  useEffect(() => {
    if (recordTypeId !== '') {
      const getData = async () => {
        const cursus = await salesforce.getCursus(ecole, recordTypeId)

        return { cursus }
      }

      getData().then((value) => {
        const { cursus } = value

        setOptionsCursus(cursus)
        componentFetched('cursus')
      }).catch((error) => {
        setOpenSnackbar(true)
        console.log('erreur', error)
        setSnackMessage('Une erreur est survenue lors du chargement du formulaire')
      })
    }
  }, [recordTypeId])

  return (
    <Grid item xs={12}>
      <Dropdown control={control} label={label} name="lead.Wished_Programm__c" options={optionsCursus} required />
    </Grid>
  )
}

Cursus.propTypes = {
  ecole: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default Cursus
