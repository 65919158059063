import { CssBaseline,  } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { default as Documentation } from "./Documentation/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import { default as JourneeImmersive } from "./JourneeImmersive/Form"
import { default as Atelier } from "./Atelier/Form"
import { default as Conference } from "./Conference/Form"
import theme from "./Theme/index"
import { FormContextProvider } from "hooks/useForm"
import Forms from "../Forms"

const DocumentationSupinfo = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesPortesOuvertesSupinfo = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneesPortesOuvertes />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneeImmersiveSupinfo = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneeImmersive />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const AtelierSupinfo = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Atelier />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const ConferenceSupinfo = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Conference />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

export { AtelierSupinfo, ConferenceSupinfo, DocumentationSupinfo, JourneeImmersiveSupinfo, JourneesPortesOuvertesSupinfo,  }
