import * as Yup from "yup";
import {
  AcademicLevel,
  Accompagnateurs,
  Availabilities,
  CampaignId,
  Email,
  FirstName,
  LastName,
  MobilePhone,
  SlotId,
} from "services/validation";

const schema = Yup.object().shape({
  ...Accompagnateurs,
  ...Availabilities,
  ...CampaignId,
  selectedOption: Yup.string().required('Le champ je suis intéressé(e) par est requis'),
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhone,
  }),
  ...SlotId,
});

export default schema;
