 export const bachelor_options = [
    { label: "Bachelor en Marketing des Luxes",
      value: "Bachelor_Luxe__c",
      academicLevels: ["Troisième", "Seconde", "Première", "Terminale (BAC)", "BAC", "Bac +1", "Bac +2", "Bac +3", "Bac +4", "Bac +5", "Autre"]
    },
    { label: "Bachelor en Haute Horlogerie Brand Management",
      value: "Bachelor_Haute_Horlogerie__c",
      academicLevels: ["Troisième", "Seconde", "Première", "Terminale (BAC)", "BAC", "Bac +1", "Bac +2", "Bac +3", "Bac +4", "Bac +5", "Autre"]
    },
    {
      label: "Bachelor International Business Management",
      value: "Bachelor_Business_Management__c",
      academicLevels: ["Troisième", "Seconde", "Première", "Terminale (BAC)", "BAC", "Bac +1", "Bac +2", "Bac +3", "Bac +4", "Bac +5", "Autre"]
    },
    {
      label: "Master Luxe & Stratégies Digitales",
      value: "Master_Luxe_et_Strategies_Digitales__c",
      academicLevels: ["Bac +3", "Bac +4", "Bac +5", "Autre"]
    },
    {
      label: "Master Luxury & Sustainability",
      value: "Master_Luxury_Mkg_and_Sustainability__c",
      academicLevels: ["Bac +3", "Bac +4", "Bac +5", "Autre"]
    },
    {
      label: "Master in Luxury Management",
      value: "Master_Luxury_Management__c",
      academicLevels: ["Bac +3", "Bac +4", "Bac +5", "Autre"]
    },
    {
      label: "MBA International Fine Watchmaking Management",
      value: "MBA_Fine_Watchmaking__c",
      academicLevels: ["Bac +3", "Bac +4", "Bac +5", "Autre"]
    }
  ]

