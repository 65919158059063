import PropTypes from "prop-types"
import { MenuItem } from "@mui/material"
import { Controller } from "react-hook-form"
import DropdownCommon from "./DropdownCommon"

const Dropdown = ({ control, label, name, options, ...rest }) => {

  const renderOptions = (options) => {
    return options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))
  }

  return (
    <Controller
      control={control}
      label={label}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error }
      }) => (
        <DropdownCommon
          {...rest}
          error={error}
          label={label}
          name={name}
          onChange={onChange}
          renderOptions={renderOptions(options)}
          value={value}
        />
      )}
    />
  )
}

Dropdown.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
}

export default Dropdown
