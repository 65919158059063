import PropTypes from "prop-types"
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { Controller } from "react-hook-form"

const RadioButton = ({ control, label, name, options, ...rest }) => {

  const { required } = rest

  const renderOptions = (options) => {
    return options.map((option) => (
      <FormControlLabel
        control={<Radio size="small" />}
        key={option.value}
        label={<Typography variant="body2">{option.label}</Typography>}
        value={option.value}
      />
    ))
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState
      }) => (
        <FormControl error={!!error}>
          <FormLabel component="legend" required={required}>{label}</FormLabel>
          <RadioGroup
            name={name}
            onChange={onChange}
            row
            value={value}
          >
            {renderOptions(options)}
          </RadioGroup>
          <FormHelperText>{error !== undefined ? error.message : ''}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

RadioButton.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
}

export default RadioButton
