/**
 * Validation commune pour les champs. Si une validation doit différer, il faut les faire directement sur le fichier correspondant
 */

import * as Yup from "yup"
import { matchIsValidTel } from "mui-tel-input"

const AcademicLevel = { AcademicLevel__c : Yup.string().required('Le champ Etudes actuelles est requis') }

const Accompagnateurs = {
  accompagnateurs: Yup.string().when('maxAccompagnateurs', {
    is: (acc) => acc !== undefined && acc.length > 0,
    then: (schema) => schema.required(`Vous devez choisir le nombre d'accompagnateurs`)
  })
}

const Availabilities = {
  availabilities: Yup.object().shape({
    dates: Yup.array().when('type', {
      is: val => val !== '',
      then: (schema) => schema.min(1, 'Vous devez choisir au minimum une disponibilité')
    }),
    skypeId: Yup.string().when('type', {
      is: val => val === 'Être rappelé(e) sur Skype',
      then: (schema) => schema.required(`L'identifiant Skype est obligatoire`)
    }),
    type: Yup.string().test('test_campaignId', 'Vous devez choisir une méthode de contact', (value, context) => {
      const campaignId = context.from.find(el => el.value.campaignId === 'unavailable')
      return (campaignId !== undefined && value !== '') || (campaignId === undefined && value === '')
    })
  })
}

const CampaignId = { campaignId: Yup.string().required('Vous devez choisir une date') }

const Campus = { Campus__c: Yup.string().required('Le campus est requis') }

const City = { City: Yup.string().required(`La ville est requise`).max(40, `La ville doit contenir au maximum 40 caractères`) }

const CityDocumentation = {
  City: Yup.string().test('test-city', `La ville est requise`, (value, ctx) => {
    const choix = ctx.from.find(el => el.value.choix === 'recevoir')
    return choix === undefined || value !== ''
  }).max(40, `La ville doit contenir au maximum 40 caractères`)
}

const ChoixDocumentation = { choix: Yup.string().required('Vous devez cocher une des deux cases') }

const Country = { Country: Yup.string().required(`Le pays est requis`) }

const CountryDocumentation = {
  Country: Yup.string().test('test-country', `Le pays est requis`, (value, ctx) => {
    const choix = ctx.from.find(el => el.value.choix === 'recevoir')
    return choix === undefined || value !== ''
  })
}

const Department = { PostalCode: Yup.string().required('Le département de résidence est requis') }

const Email = { Email: Yup.string().email(`Le format de l'adresse e-mail est incorrect`).required(`L'adresse e-mail est requise`).max(40, `L'adresse e-mail doit contenir au maximum 40 caractères`) }

const FirstName = { FirstName: Yup.string().required('Le prénom est requis').max(64, 'Le prénom doit contenir au maximum 64 caractères') }

const LastName = { LastName: Yup.string().required('Le nom est requis').max(64, 'Le nom doit contenir au maximum 64 caractères') }

const MobilePhone = { MobilePhone: Yup.string().max(64, 'Le téléphone doit contenir au maximum 64 caractères').test('test-mobile-phone', 'Le numéro que vous avez renseigné semble incorrect',
  (val) => {
    return val === '' || matchIsValidTel(val)
  })
}

const MobilePhoneRequired = { MobilePhone: Yup.string().required('Le téléphone est requis').max(64, 'Le téléphone doit contenir au maximum 64 caractères').test('test-mobile-phone', 'Le numéro que vous avez renseigné semble incorrect',
  (val) => {
    return val === '' || matchIsValidTel(val)
  })
}

const PostalCode = { PostalCode: Yup.string().required('Le code postal est requis').max(20, 'Le code postal doit contenir au maximum 20 caractères') }

const PostalCodeDocumentation = {
  PostalCode: Yup.string().test('test-postalcode', `Le code postal est requis`, (value, ctx) => {
    const choix = ctx.from.find(el => el.value.choix === 'recevoir')
    return choix === undefined || value !== ''
  }).max(20, `Le code postal doit contenir au maximum 40 caractères`)
}

const PostalCodeNotRequired = { PostalCode: Yup.string().max(20, 'Le code postal doit contenir au maximum 20 caractères') }

const Salutation = { Salutation: Yup.string().required('La civilité est requise') }

const Section = {
  Section__c: Yup.string().test('test-section', 'La section est requise', (value, ctx) => {
    const sections = ctx.from.find(el => el.value.sections?.length > 0)
    return value !== '' || (sections === undefined && value === '')
  })
}

const Street = { Street: Yup.string().required(`L'adresse est requise`).max(255, `L'adresse doit contenir au maximum 255 caractères`) }

const StreetDocumentation = {
  Street: Yup.string().test('test-street', `L'adresse est requise`, (value, ctx) => {
    const choix = ctx.from.find(el => el.value.choix === 'recevoir')
    return choix === undefined || value !== ''
  }).max(255, `L'adresse doit contenir au maximum 255 caractères`)
}

const SlotId = {
  slotId: Yup.string().when('slots', {
    is: (slots) => slots !== undefined && slots.length > 0,
    then: (schema) => schema.required('Vous devez choisir un créneau')
  })
}

const Specialites = {
  specialites: Yup.array().when('lead.AcademicLevel__c', {
    is: 'Terminale (BAC)',
    then: (schema) => schema.when('lead.Section__c', {
      is: 'Générale',
      then: (schema) => schema.min(2, 'Vous devez choisir deux spécialités').max(2, 'Vous pouvez choisir au maximum deux spécialités')
    })
  }).when('lead.AcademicLevel__c', {
    is: 'Première',
    then: (schema) => schema.when('lead.Section__c', {
      is: 'Générale',
      then: (schema) => schema.min(3, 'Vous devez choisir trois spécialités').max(3, 'Vous pouvez choisir au maximum trois spécialités')
    })
  })
}

const WishedProgramm = { Wished_Programm__c: Yup.string().required(`Vous devez choisir un cursus d'intérêt`) }

export { AcademicLevel, Accompagnateurs, Availabilities, CampaignId, Campus, ChoixDocumentation, City, CityDocumentation, Country, CountryDocumentation, Department, Email, FirstName, LastName, MobilePhone, MobilePhoneRequired, PostalCode, PostalCodeDocumentation, PostalCodeNotRequired, Salutation, Section, SlotId, Specialites, Street, StreetDocumentation, WishedProgramm }
