import { default as LandingPage } from "./Form";
import { FormContextProvider } from "hooks/useForm";
import Forms from "../Forms";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import {customTheme} from "./Theme/index";

const LandingPageForm = ({ params }) => {
    const json = params ? JSON.parse(params) : {}
    const campuses = json.campuses
    let campus
    if (campuses) {
        if (campuses.length === 1) {
            campus = [{ label: campuses[0], value: campuses[0] }];
        } else {
            campus = campuses.map(camp => ({ label: camp, value: camp }));
        }
    }
    const theme = customTheme(json.color, json.backgroundColor, json.hover)
  
    return (
        <FormContextProvider>
            <ThemeProvider theme={theme}>
                <Forms>
                    <CssBaseline />
                    <LandingPage
                        {...json}
                        campuses={campus}
                    />
                </Forms>
            </ThemeProvider>
        </FormContextProvider>
    );
};

export { LandingPageForm };
