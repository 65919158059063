import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { useFormContext } from "react-hook-form"
import InputText from "components/fields/InputText"
import Dropdown from "components/fields/Dropdown"
import { useForms } from "hooks/useForm"

const Adresse = ({ eda = false }) => {

  const { control } = useFormContext()
  const { componentFetched, salesforce, setComponentStates, setOpenSnackbar, setSnackMessage } = useForms()

  const [countries, setCountries] = useState([])

  useEffect(() => {
    setComponentStates((prevState) => [
      ...prevState,
      { name: 'countries', added: true, fetched: false },
    ])
  }, [setComponentStates])

  useEffect(() => {
    if (salesforce !== null) {
      const getData = async () => {
        let countriesId = ''
        if (eda) {
          countriesId = '0NtSm00000006Td'
        } else {
          countriesId = '0Nt08000000bqgi'
        }
        const countries = await salesforce?.getCountries(countriesId, ['France', 'Belgique', 'Suisse'])

        return { countries }
      }

      getData().then((value) => {
        const { countries } = value
        setCountries(countries)
        componentFetched('countries')
      }).catch((error) => {
        setOpenSnackbar(true)
        console.log('erreur', error)
        setSnackMessage('Une erreur est survenue lors du chargement du formulaire')
      })
    }
  }, [salesforce])

  return (
    <>
      <Grid item xs={12}>
        <InputText control={control} label="Adresse" multiline name="lead.Street" required rows={3} />
      </Grid>
      <Grid item sm={4} xs={12}>
        <InputText control={control} label="Code postal" name="lead.PostalCode" required />
      </Grid>
      <Grid item sm={4} xs={12}>
        <InputText control={control} label="Ville" name="lead.City" required />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Dropdown control={control} label="Pays" name="lead.Country" options={countries} required />
      </Grid>
    </>
  )
}

Adresse.propTypes = {
  eda: PropTypes.bool
}

export default Adresse;
