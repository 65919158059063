import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { Email, InputText, PhoneNumber } from "components/fields";
import { AcademicLevel, Campus, Consentement, RequiredFields, Submit } from "components";
import { useForms, useSalesforce } from "hooks";
import { getCampus, defaultValues } from "services";
import schemas from "./Validation";
import { DevTool } from '@hookform/devtools';
import queryString from "query-string"
import Formations from 'Forms/ESME/components/Formations';



const Form = ({
    division,
    campuses,
    school,
    lastForm, leadSource, defaultCampaignId, status, description, subject, form, recordTypeName, academics,
    theme, firstNameRequired, lastNameRequired, emailRequired, phoneNumberRequired, academicLevelRequired, campusRequired }) => {
    const { recordTypeId, salesforce, setOpenSnackbar } = useForms();
    const requiredFields = {
        firstNameRequired,
        lastNameRequired,
        emailRequired,
        phoneNumberRequired,
        academicLevelRequired,
        campusRequired
    };
    const methods = useForm({
        defaultValues: {
            formations: [],
            lead: {
                ...defaultValues,
                Apprentissage__c: false,
                Bachelor__c: false,
                CycleInge__c: false,
                Master__c: false,
            },
        },
        context: {
            ...requiredFields
        },
        resolver: yupResolver(schemas)
    });

    const { control, handleSubmit, setValue } = methods;
    const { campaignId, utm_id, campaignid, campaign_id } = queryString.parse(document.location.search)
    const [showConfirmation, setShowConfirmation] = useState(false);



    const onSubmit = async (data) => {
        setOpenSnackbar(false);
        setShowConfirmation(true);
        setTimeout(() => {
            setShowConfirmation(false);
        }, 20000);
        const lead = data.lead;
        const defaultDataLead = {
            Division__c: division,
            IONISSchool__c: school,
            LastForm__c: lastForm,
            LeadSource: leadSource,
            Status: status,
            RecordTypeId: recordTypeId,
            //Campus__c: campus,
        }
        const finalLead = { ...lead, ...defaultDataLead };
        try {
            const createdLead = await salesforce.createLead(finalLead);
            let campaignMember = {
                CampaignId: defaultCampaignId,
                LeadId: createdLead.Id,
                Status: status
            }
            const task = {
                ActivityDate: new Date(),
                Description: description,
                OwnerId: createdLead.OwnerId,
                Subject: subject,
                WhoId: createdLead.Id
            }
            await salesforce.createCampaignMember(campaignMember)
            const externalId = campaignId || utm_id || campaign_id || campaignid;
            if (externalId) {
                await salesforce.createCampaignMemberExternal(campaignMember, externalId)
            }
            await salesforce.createTask(task);
            setTimeout(() => {
                window.location.reload();
            }, 500000);
        } catch (error) {
            console.error("error", error);
        }
    }
    
    useSalesforce(recordTypeName);
    useEffect(() => {
        if (campuses) {
            if (campuses.length === 1) {
                const campus = campuses[0].value;
                setValue("lead.Campus__c", campus);
            }
        }
    }, [campuses, setValue])

    useEffect(() => {
        if (academics) {  
            if (academics.length === 1) {
                const academic = academics[0];
                setValue("lead.AcademicLevel__c", academic);

            }
        }
    }, [academics, setValue])
    return (
        <FormProvider {...methods}>
            <DevTool control={control} />
            <Grid container spacing={2} style={{ ...theme }}>
                {firstNameRequired && (
                    <Grid item xs={12}>
                        <InputText control={control} label="Prénom" name="lead.FirstName" required={firstNameRequired} />
                    </Grid>
                )}
                {lastNameRequired && (
                    <Grid item xs={12}>
                        <InputText control={control} label="Nom" name="lead.LastName" required={lastNameRequired} />
                    </Grid>

                )}
                {emailRequired && (
                    <Grid item xs={12}>
                        <Email control={control} label="Email" name="lead.Email" required={emailRequired} />
                    </Grid>
                )}
                {phoneNumberRequired && (
                    <Grid item xs={12}>
                        <PhoneNumber control={control} label="Numéro de téléphone" name="lead.MobilePhone" required={phoneNumberRequired} />
                    </Grid>
                )}
                {(academicLevelRequired && academics.length > 1) && (
                    <Grid item xs={12}>
                        <AcademicLevel academics={academics} school={school} section={false} specialites={false} required={academicLevelRequired} />
                    </Grid>
                )}
                {(campusRequired && campuses.length > 1) && (
                    <Grid item xs={12}>
                        <Campus label="Campus souhaité" options={campuses || getCampus(school)} required={campusRequired}/>
                    </Grid>
                )}
                <Formations/>
                <Consentement school={school} form={form} />
                <RequiredFields />
                <Grid item xs={12}>
                    <Submit onSubmit={handleSubmit(onSubmit)} />
                </Grid>
                {showConfirmation && (
                    <Grid item xs={12}>
                        <div style={{ color: 'green' }}>Votre formulaire a été soumis avec succès!</div>
                    </Grid>
                )}
            </Grid>
        </FormProvider>
    );
};

export default Form;

