import PropTypes from "prop-types"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Campus, Consentement, RequiredFields, Submit } from "components"
import { Email, InputText } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { defaultValues, getCampus } from "services"

const Form = ({ parent = false }) => {
  const campaignId = parent ? '701080000012NxnAAE' : '701080000012NxiAAE'
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      campaignId: campaignId,
      lead: {
        ...defaultValues,
        AcademicLevel__c: parent ? 'Autre': '',
        Salutation: ''
      },
    },
    resolver: yupResolver(schema)
  })
  const { control, watch } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId
    const defaultDataLead = {
      Division__c: 'e-artsup',
      IONISSchool__c: 'e-artsup',
      LastForm__c: parent ? 'Documentation PDF - LB Parents' : 'Documentation PDF - LB Etudiants',
      LeadSource: 'Internet: Demande de doc',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      const campaignMember = {
        CampaignId: campaignId,
        LeadId: leadCreated.Id,
        Status: 'Active'
      }
      await salesforce.createCampaignMember(campaignMember)
      if (parent) {
        document.location.href=`https://www.e-artsup.net/telechargez-le-livre-blanc-guide-dorientation-pour-les-etudes-superieures/?ville=${campus}&campaignId=${campaignId}`
      } else {
        document.location.href=`https://www.e-artsup.net/telecharge-le-livre-blanc-le-guide-des-jeunes-creatifs/?ville=${campus}&campaignId=${campaignId}`
      }
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const campus = watch('lead.Campus__c')
  // useEffect
  useSalesforce('BusinessSchoolLead')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        {!parent && (
          <AcademicLevel school="e-artsup" section={false} specialites={false} />
        )}
        <Campus label="Campus d’intérêt" options={getCampus('e-artsup')} />
        <Consentement form="Livre Blanc" school="e-artsup" />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
      </Grid>
    </FormProvider>
  )
}

Form.propTypes = {
  parent: PropTypes.bool
}

export default Form
