import { Grid } from "@mui/material"
import { Dropdown } from "components/fields"
import { useForms } from "hooks"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

const Departments = ({ label }) => {

  const { control } = useFormContext()
  const { componentFetched, recordTypeId, salesforce, setComponentStates, setOpenSnackbar, setSnackMessage } = useForms()
  const [optionsDepartments, setOptionsDepartments] = useState([])

  useEffect(() => {
    setComponentStates((prevState) => [
      ...prevState,
      { name: 'departments', added: true, fetched: false },
    ])
  }, [setComponentStates])

  useEffect(() => {
    if (recordTypeId !== '') {
      const getData = async () => {
        const departments = await salesforce.getDepartments()
        return { departments }
      }

      getData().then((value) => {
        const { departments } = value

        setOptionsDepartments(departments)
        componentFetched('departments')
      }).catch((error) => {
        setOpenSnackbar(true)
        console.log('erreur', error)
        setSnackMessage('Une erreur est survenue lors du chargement du formulaire')
      })
    }
  }, [recordTypeId])

  return (
    <Grid item xs={12}>
      <Dropdown control={control} label={label} name="lead.PostalCode" options={optionsDepartments} required />
    </Grid>
  )
}

export default Departments
