import PropTypes from "prop-types"
import { CssBaseline,  } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { default as Documentation } from "./Documentation/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import { default as Digiclub } from "./Digiclub/Form"
import { default as Contact } from "./Contact/Form"
import { default as ContactRD } from "./ContactRD/Form"
import theme from "./Theme/index"
import Forms from "../Forms"
import { FormContextProvider } from "hooks/useForm"

const ContactEpitechDigital = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Contact />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const ContactRDEpitechDigital = ({ campaignid }) => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <ContactRD campaignId={campaignid} />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const DigiclubEpitechDigital = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Digiclub />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const DocumentationEpitechDigital = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesPortesOuvertesEpitechDigital = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneesPortesOuvertes />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

ContactRDEpitechDigital.propTypes = {
  campaignId: PropTypes.string
}

export { ContactEpitechDigital, ContactRDEpitechDigital, DocumentationEpitechDigital, DigiclubEpitechDigital, JourneesPortesOuvertesEpitechDigital }
