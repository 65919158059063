import { AcademicLevel, Campus, ChoixDocumentation, CityDocumentation, CountryDocumentation, Email, FirstName, LastName, MobilePhone, MobilePhoneRequired, PostalCodeDocumentation, Salutation, Section, Specialites, StreetDocumentation } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  ...ChoixDocumentation,
  consent: Yup.string().required('Veuillez faire un choix'),
  formations: Yup.array().min(1, 'Vous devez choisir au minimum une formation'),
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...CityDocumentation,
    ...CountryDocumentation,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhone,
    ...PostalCodeDocumentation,
    ...Salutation,
    ...StreetDocumentation,
    ...Section
  }),
  ...Specialites
})

export default schema
