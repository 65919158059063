const optionsDates = [
  { label: 'Lundi matin', value: 'Lundi matin' },
  { label: 'Lundi après-midi', value: 'Lundi après-midi' },
  { label: 'Mardi matin', value: 'Mardi matin' },
  { label: 'Mardi après-midi', value: 'Mardi après-midi' },
  { label: 'Mercredi matin', value: 'Mercredi matin' },
  { label: 'Mercredi après-midi', value: 'Mercredi après-midi' },
  { label: 'Jeudi matin', value: 'Jeudi matin' },
  { label: 'Jeudi après-midi', value: 'Jeudi après-midi' },
  { label: 'Vendredi matin', value: 'Vendredi matin' },
  { label: 'Vendredi après-midi', value: 'Vendredi après-midi' },
]

const APPOINTMENT = 'Prendre RDV pour un entretien'
const CALL_PHONE = 'Être rappelé(e) par téléphone'
const CALL_SKYPE = 'Être rappelé(e) sur Skype'
const CALL_WHATSAPP = 'Être rappelé(e) sur WhatsApp'

const optionsAvailabilities = [
  { label: CALL_PHONE, value: CALL_PHONE },
  { label: CALL_SKYPE, value: CALL_SKYPE },
  { label: APPOINTMENT, value: APPOINTMENT }
]

const optionsAvailabilitiesEpitech = [
  { label: CALL_PHONE, value: CALL_PHONE },
  { label: CALL_WHATSAPP, value: CALL_WHATSAPP },
  { label: APPOINTMENT, value: APPOINTMENT }
]

export { APPOINTMENT, CALL_PHONE, CALL_SKYPE, CALL_WHATSAPP, optionsAvailabilities, optionsAvailabilitiesEpitech, optionsDates }
