import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Campaigns, Campus, Consentement, Cursus, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { campaigns, defaultValues, getCampus } from "services"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...campaigns,
      lead: {
        ...defaultValues,
        Salutation: '',
        Section__c: '',
        Wished_Programm__c: ''
      },
    },
    resolver: yupResolver(schema)
  })
  const { control, watch } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId
    const defaultDataLead = {
      Division__c: 'e-artsup',
      IONISSchool__c: 'e-artsup',
      LeadSource: campaignId !== 'unavailable' ? `JPO` : 'Demande de rendez-vous',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      if (campaignId !== 'unavailable') {
        const campaignMember = {
          CampaignId: campaignId,
          LeadId: leadCreated.Id,
          Status: 'Registered'
        }
        await salesforce.createCampaignMember(campaignMember)
        document.location.href=`https://www.e-artsup.net/retour-journees-portes-ouvertes/?ville=${campus}&campaignId=${campaignId}`
      } else {
        let description = ''

        if (data.availabilities.type === 'Être rappelé(e) sur Skype') {
          description = 'ID Skype : ' + data.availabilities.skypeId + '\n\n'
        }
        description += data.availabilities.dates.join(', ')

      const subject = data.availabilities.type
        const task = {
          ActivityDate: new Date(),
          Description: description,
          OwnerId: leadCreated.OwnerId,
          Subject: subject,
          WhoId: leadCreated.Id
        }

        await salesforce.createTask(task)
        document.location.href=`https://www.e-artsup.net/retour-demande-entretien/?ville=${campus}`
      }
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const campus = watch('lead.Campus__c')
  // useEffect
  useSalesforce('BusinessSchoolLead')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Campus label="Ville choisie pour participer à la Journée Portes Ouvertes" options={getCampus('e-artsup')} />
        <Campaigns contact={false} division="e-artsup" last_form="JPO" school="e-artsup" text="à la Journée Portes Ouvertes" textAvailability="pour nos journées portes ouvertes" type="JPO" />
        <Cursus ecole="e-artsup" label="Programme d'intérêt" />
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone mobile" required />
        </Grid>
        <AcademicLevel school="e-artsup" section={false} specialites={false} />
        <Consentement form="Journée Immersion" school="e-artsup" />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
      </Grid>
    </FormProvider>
  )
}

export default Form
