import PropTypes from "prop-types"
import { Grid, Typography } from "@mui/material"
import { labelConsentement } from "services/consentement"

const MentionsLegales = ({ form, school }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="body2" sx={{ mb: 2 }}>{labelConsentement(form, school)}</Typography>
    </Grid>
  )
}

MentionsLegales.propTypes = {
  form: PropTypes.string.isRequired,
  school: PropTypes.string.isRequired
}

export default MentionsLegales
