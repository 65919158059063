import { AcademicLevel, Campus, Email, FirstName, LastName } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...Email,
    ...FirstName,
    ...LastName
  })
})

export default schema
