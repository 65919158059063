import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, ConsentementMultiple, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { consent, consentement, defaultValues, findCampusFromUrl } from "services"
import { useEffect } from "react"
import MentionsLegales from "components/MentionsLegales"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...consent,
      lead: {
        ...defaultValues,
        ...consentement
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, setValue } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campus = lead.Campus__c
    const defaultDataLead = {
      Division__c: 'ISEG MCS',
      IONISSchool__c: 'ISEG MCS',
      LastForm__c: 'Internet : contact - simplifié',
      LeadSource: 'Internet: Demande de contact',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}

    try {
      const leadCreated = await salesforce.createLead(finalLead)
        const task = {
          ActivityDate: new Date(),
          Description: '',
          OwnerId: leadCreated.OwnerId,
          Subject: 'Ce prospect souhaite être recontacté',
          WhoId: leadCreated.Id
        }

        await salesforce.createTask(task)
        document.location.href=`${process.env.REACT_APP_ISEG_URL}/contact-retour-ok/?ville=${campus}&niveau=${encodeURIComponent(lead.AcademicLevel__c)}`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  // useEffect
  useEffect(() => {
    // Récupération du campus
    setValue('lead.Campus__c', findCampusFromUrl(/-([a-zA-Z]+)$/))
  }, [setValue])
  useSalesforce('BusinessSchoolLead')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <AcademicLevel school="ISEG MCS" section={false} specialites={false} />
        <ConsentementMultiple />
        <RequiredFields />
        <Submit label="Je souhaite être contacté par l'école" onSubmit={onSubmit} />
        <MentionsLegales form="Contact" school="ISEG MCS" />
      </Grid>
    </FormProvider>
  )
}

export default Form
