import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "components/fields/Dropdown";
import { DevTool } from "@hookform/devtools";
import schema from "./Validation";
import { Grid } from "@mui/material";
import { bachelor_options } from "./bachelor_options";
import {
  AcademicLevel,
  Campaigns,
  Disponibilites,
  RequiredFields,
  Submit,
} from "components";
import { Email, InputText, PhoneNumber } from "components/fields";
import { useForms, useSalesforce } from "hooks";
import { campaigns, defaultValues, getAcademicLevels } from "services";
import { useEffect, useState } from "react"

const Form = () => {
  const {
    recordTypeId,
    salesforce,
    setError,
    setOpenSnackbar,
    setSnackMessage,
    setSnackSeverity,
  } = useForms();

  const methods = useForm({
    defaultValues: {
      ...campaigns,
      selectedOption: "",
      MaxNumberOfEventAttendants__c: "",
      lead: {
        ...defaultValues,
        EventDateAndDuration__c: "", // Utile ?
        LastForm__c: "JPO",
        Campus__c: "Genève",
      },
    },
    resolver: yupResolver(schema),
  });
  const { control, setValue, watch } = methods;

  const onSubmit = async (data) => {
    const selectedOptions = {
      [data.selectedOption]: true,
    };
    setOpenSnackbar(false);
    const { lead } = data;
    const campaignId = data.campaignId;
    const slotId = data.slotId;
    const places = data.accompagnateurs || 0;
    if (slotId !== "") {
      const slot = await salesforce.checkSlot(slotId, places);
      if (slot.length === 0) {
        setOpenSnackbar(true);
        setSnackMessage(
          "Désolé les dernières places ont été prises. Veuillez choisir une autre date ou un autre créneau"
        );
        setSnackSeverity("warning");
        return;
      }
    }
    const defaultDataLead = {
      Division__c: "ISG Luxury",
      IONISSchool__c: "ISG",
      LeadSource:
        campaignId !== "unavailable" ? "JPO" : "Internet: Demande de contact",
      RecordTypeId: recordTypeId,
    };
    const finalLead = { ...lead, ...defaultDataLead, ...selectedOptions };
    try {
      const leadCreated = await salesforce.createLead(finalLead);
      if (campaignId !== "unavailable") {
        const campaignMember = {
          CampaignId: campaignId,
          LeadId: leadCreated.Id,
          Status: data.status,
        };
        if (slotId !== "") {
          campaignMember.EventSlot__c = slotId;
          campaignMember.NumberOfPerson__c = places;
        }
        await salesforce.createCampaignMember(campaignMember);
        document.location.href = `
        https://www.isg-luxury.ch/reunions-info-bachelors-et-masters-ok/`;
      } else {
        let description = "";

        if (data.availabilities.type === "Être rappelé(e) sur Skype") {
          description = "ID Skype : " + data.availabilities.skypeId + "\n\n";
        }
        description += data.availabilities.dates.join(", ");
        const subject = data.availabilities.type;
        const task = {
          ActivityDate: new Date(),
          Description: description,
          OwnerId: leadCreated.OwnerId,
          Subject: subject,
          WhoId: leadCreated.Id,
        };

        await salesforce.createTask(task);
        document.location.href = `
        https://www.isg-luxury.ch/reunions-info-bachelors-et-masters-ok/`;
      }
    } catch (error) {
      setError(
        "Une erreur est survenue lors de la soumission du formulaire",
        error
      );
    }
  };
  const [optionsInteresse, setOptionsInteresse] = useState([])
  // watch
  const academicLevel = watch("lead.AcademicLevel__c")
  const watchInteresse = watch('selectedOption')
  // useEffect
  useSalesforce("ISG Luxury");
  useEffect(() => {
    if (academicLevel !== "") {
      const selectedOption = bachelor_options.filter(
        (option) => option.academicLevels.includes(academicLevel)
      )
      if (selectedOption.find(el => el.value === watchInteresse) === undefined) {
        setValue('selectedOption', '')
      }
      setOptionsInteresse(selectedOption)
    }
  }, [academicLevel])

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Campaigns
          division="ISG Luxury"
          school="ISG"
          text="Je souhaite participer à la réunion d'information"
          type="JPO"
        />
        <Disponibilites text="Pour nos réunions d'information" />
        <AcademicLevel
          options={getAcademicLevels('ISG Luxury')}
          section={false}
          specialites={false}
        />
        <Grid item sm={12} xs={12}>
          <Dropdown
            control={control}
            disabled={academicLevel === ""}
            label="Je suis intéressé(e) par"
            name="selectedOption"
            options={optionsInteresse}
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText
            control={control}
            label="Prénom"
            name="lead.FirstName"
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText
            control={control}
            label="Nom"
            name="lead.LastName"
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email
            control={control}
            label="Adresse e-mail"
            name="lead.Email"
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber
            control={control}
            defaultCountry="CH"
            name="lead.MobilePhone"
            label="Téléphone"
          />
        </Grid>
        <Submit onSubmit={onSubmit} />
        <RequiredFields />
      </Grid>
    </FormProvider>
  );
};

export default Form;
