const campaignStatus = [
  {
    name: 'Registered',
    online: false,
    eda: true
  },
  {
    name: 'Registered',
    online: false,
    eda: false
  },
  {
    name: 'Registered online',
    online: true,
    eda: true
  },
  {
    name: 'RegisteredOnline',
    online: true,
    eda: false
  }
]

export default campaignStatus
