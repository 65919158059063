import PropTypes from "prop-types"
import { Grid } from "@mui/material"
import Dropdown from "components/fields/Dropdown"
import { useForms } from "hooks/useForm"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

const Campus = ({ label, options }) => {

  const { control } = useFormContext()
  const { componentFetched, recordTypeId, salesforce, setComponentStates, setOpenSnackbar, setSnackMessage } = useForms()
  const [optionsCampus, setOptionsCampus] = useState([])

  useEffect(() => {
    setComponentStates((prevState) => [
      ...prevState,
      { name: 'campus', added: true, fetched: false },
    ])
  }, [setComponentStates])

  useEffect(() => {
    if (options === undefined && recordTypeId !== '') {
      const getData = async () => {
        const campus = await salesforce.getCampus(recordTypeId)
        return { campus }
      }

      getData().then((value) => {
        const { campus } = value

        setOptionsCampus(campus)
        componentFetched('campus')
      }).catch((error) => {
        setOpenSnackbar(true)
        console.log('erreur', error)
        setSnackMessage('Une erreur est survenue lors du chargement du formulaire')
      })
    }
    if (options !== undefined) {
      componentFetched('campus')
    }
  }, [recordTypeId])

  return (
    <Grid item xs={12}>
      <Dropdown control={control} label={label} name="lead.Campus__c" options={options || optionsCampus} required />
    </Grid>
  )
}

Campus.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array
}

export default Campus
