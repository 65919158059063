import queryString from "query-string"

const assignCampaign = (campaigns, setValue) => {
  const { CampaignId } = queryString.parse(document.location.search)
  if (CampaignId !== undefined) {
    // On cherche le campus et la date selon cet id
    const campaign = campaigns.find(el => el.Id === CampaignId)
    if (campaign !== undefined) {
      setValue('lead.Campus__c', campaign.Campus__c)
      setValue('campaignId', campaign.Id)
      if (campaign.Campus__c === 'National') {
        setValue('campaignNational', true)
        setValue('lead.Campus__c', '')
      }
    }
  }
}

export { assignCampaign }
