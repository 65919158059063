import { AcademicLevel, Campus, ChoixDocumentation, CityDocumentation, CountryDocumentation, Email, FirstName, LastName, MobilePhoneRequired, PostalCodeDocumentation, StreetDocumentation } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  ...ChoixDocumentation,
  consent: Yup.string().required('Veuillez faire un choix'),
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...CityDocumentation,
    ...CountryDocumentation,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhoneRequired,
    ...PostalCodeDocumentation,
    ...StreetDocumentation
  })
})

export default schema
