import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Campaigns, Consentement, Disponibilites, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber, RadioButton } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { campaigns, defaultValues, getAcademicLevels, salutations } from "services"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...campaigns,
      lead: {
        ...defaultValues,
        Campus__c: 'Paris',
        Salutation: ''
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, formState: { isSubmitting }, handleSubmit } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId
    const defaultDataLead = {
      Division__c: 'EPITA IA Institut',
      IONISSchool__c: 'EPITA IA Institut',
      LeadSource: campaignId !== 'unavailable' ? 'Atelier' : 'Demande de rendez-vous',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      if (campaignId !== 'unavailable') {
        const campaignMember = {
          CampaignId: campaignId,
          LeadId: leadCreated.Id,
          Status: data.status
        }
        await salesforce.createCampaignMember(campaignMember)
        document.location.href=`${process.env.REACT_APP_IA_INSTITUT_URL}/ecole-intelligence-artificielle/atelier-decouverte/inscription-validee/?campaignId=${campaignId}`
      } else {
        let description = ''

        description += data.availabilities.dates.join(', ')

      const subject = data.availabilities.type
        const task = {
          ActivityDate: new Date(),
          Description: description,
          OwnerId: leadCreated.OwnerId,
          Subject: subject,
          WhoId: leadCreated.Id
        }

        await salesforce.createTask(task)
        document.location.href=`${process.env.REACT_APP_IA_INSTITUT_URL}/ecole-intelligence-artificielle/journees-immersion/retour-demande-entretien/`
      }
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  // useEffect
  useSalesforce('EngineeringSchoolLead')
  // Functions

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Campaigns division="EPITA IA Institut" school="EPITA IA Institut" text="à l'atelier" type="Atelier" />
        <Disponibilites skype={false} text="pour nos ateliers" />
        <Grid item xs={12}>
          <RadioButton control={control} label="Civilité" name="lead.Salutation" options={salutations} />
        </Grid>
        <Grid item sm={12} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={12} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={12} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <AcademicLevel options={getAcademicLevels('EPITA IA Institut')} school="EPITA IA Institut" section={false} specialites={false} />
        <Consentement form="Atelier" school="EPITA IA Institut" />
        <RequiredFields />
        <Submit handleSubmit={handleSubmit} isSubmitting={isSubmitting} onSubmit={onSubmit} />
      </Grid>
    </FormProvider>
  )
}

export default Form
