import { AcademicLevel, Campus, Department, Email, FirstName, LastName, MobilePhone, MobilePhoneRequired, Salutation, Section, Specialites } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  consent: Yup.string().required('Veuillez faire un choix'),
  schools: Yup.array().min(1, 'Veuillez sélectionner au moins une école. Si vous ne savez pas encore, cochez les 4 écoles.'),
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Department,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhone,
    // ...MobilePhoneRequired,
    ...Salutation
  })
})

export default schema
