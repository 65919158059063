const choixDocumentation = [
  {
    label: "Télécharger la documentation",
    value: "telecharger"
  },
  {
    label: "Recevoir la documentation",
    value: "recevoir"
  }
]

export default choixDocumentation
