const salutations =
  [
    {
      label: "Madame",
      value: "Mme"
    },
    {
      label: "Monsieur",
      value: "M."
    }
  ]

  export default salutations
