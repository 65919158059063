import { CssBaseline,  } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { default as Documentation } from "./Documentation/Form"
import theme from "./Theme/index"
import Forms from "../Forms"
import { FormContextProvider } from "hooks/useForm"

const DocumentationAdvance = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

export { DocumentationAdvance }
