import PropTypes from "prop-types"
import { Grid } from "@mui/material"
import InputCheckbox from "components/fields/InputCheckbox"
import { useFormContext } from "react-hook-form"
import { labelConsentement } from "services/consentement"

/**
 *
 * @param {{
* form: "Atelier" | "Conference" | "Contact" | "Documentation" | "JPO" | "Journée Immersion" | "Livre Blanc";
* }} props Props for the component
*
*/
const Consentement = ({ form, school }) => {

  const { control } = useFormContext()

  return (
    <Grid item xs={12}>
      <InputCheckbox control={control} label={form === 'Documentation' ? `J'accepte que les informations saisies soient utilisées pour me communiquer des informations et évènements en lien avec ma demande initiale.` : ((form === 'JPO' || form === 'JDMI') && school === 'ISEG MCS') ? `J'accepte que l'ISEG enregistre et traite mes données personnelles pour m'inviter aux futurs événements organisés par l'ISEG.` : labelConsentement(form, school)} name="lead.MarketingOptIn__c" />
    </Grid>
  )
}

Consentement.propTypes = {
  form: PropTypes.string.isRequired,
  school: PropTypes.string.isRequired
}

export default Consentement
