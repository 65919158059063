import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { AcademicLevel, Consentement, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { defaultValues, getAcademicLevels } from "services"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      campaignId: '',
      lead: {
        ...defaultValues,
        Campus__c: 'Paris'
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, formState: { isSubmitting }, handleSubmit, setValue, watch } = methods
  // State
  const [campaigns, setCampaigns] = useState([])
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId
    const defaultDataLead = {
      Division__c: 'EPITA IA Institut',
      IONISSchool__c: 'EPITA IA Institut',
      LastForm__c: 'pre-candidature',
      LeadSource: 'Pré-candidature',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      if (campaignId !== '') {
        const campaignMember = {
          CampaignId: campaignId,
          LeadId: leadCreated.Id,
          Status: 'Pre-application'
        }
        await salesforce.createCampaignMember(campaignMember)
      }
      document.location.href=`${process.env.REACT_APP_IA_INSTITUT_URL}/admission-ecole-ia/pre-candidature/confirmation/?campaignId=${campaignId}`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const campus = watch('lead.Campus__c')
  // useEffect
  useEffect(() => {
    if (salesforce !== null) {
      const getData = async () => {
        const campaigns = await salesforce.getCampaignsCandidature('EPITA IA Institut', 'EPITA IA Institut', 'Candidature')

        return { campaigns }
      }

      getData().then((value) => {
        const { campaigns } = value
        setCampaigns(campaigns)
        // setOpenBackdrop(false)
      }).catch((error) => {
        setError('Une erreur est survenue lors du chargement du formulaire', error)
      })
    }
  }, [salesforce, setError])

  useSalesforce('EngineeringSchoolLead')

  useEffect(() => {
    if (campus !== '') {
      const campaign = campaigns.find(el => el.Campus__c === campus)
      if (campaign !== undefined) {
        setValue('campaignId', campaign.Id)
      }
    }
  }, [campaigns, campus, setValue])

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <AcademicLevel options={getAcademicLevels('EPITA IA Institut', 'Candidature')} school="EPITA IA Institut" section={false} />
        <Consentement form="Candidature" school="EPITA IA Institut" />
        <RequiredFields />
        <Submit handleSubmit={handleSubmit} isSubmitting={isSubmitting} label="Valider ma pré-candidature" onSubmit={onSubmit} />
      </Grid>
    </FormProvider>
  )
}

export default Form
