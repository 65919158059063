export function findFormations(campus, academicLevel, formations) {
  return formations.filter(formation => formation.campus.includes(campus) && formation.academicLevels.includes(academicLevel)).map(el => ({
    field: el.field,
    label: el.formation,
    value: el.formation
  }))
}

export function constructParams(formationsChoisies, formations) {
  const formationsParams = formations.filter(el => formationsChoisies.includes(el.formation)).map(el => '&programme[]=' + el.param)
  return formationsParams.join('')
}
