import PropTypes from "prop-types"
import { Alert, Backdrop, CircularProgress, Paper, Snackbar } from "@mui/material"
import { useForms } from "hooks/useForm"

const Forms = ({ children, elevation = 1, sx = { p: 2 } }) => {

  const { fetched, openSnackbar, snackMessage } = useForms()

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
      <Paper elevation={elevation} sx={sx}>
        <Backdrop open={!fetched} sx={{ color: '#fff', zIndex: 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {children}
      </Paper>
    </>
  )
}

Forms.propTypes = {
  children: PropTypes.node.isRequired,
  elevation: PropTypes.number,
  sx: PropTypes.object
}

export default Forms
