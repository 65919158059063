import { createTheme } from "@mui/material"

const defaultTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          position: 'relative'
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backgroundColor: 'rgba(0, 0, 0, 0.2)',
          position: 'absolute !important'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: 'red'
        },
        filled: {
          fontSize: '16px'
        },
        root: {
          '&.MuiInputLabel-formControl.Mui-focused': {
            fontSize: '16px'
          },
          '&.Mui-focused': {
            fontSize: '14px'
          },
          fontSize: '14px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: '1.8em',
          '&.Mui-disabled': {
            // cursor: 'not-allowed' utile ?????
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '14px',
          height: '1.8em',
          '&.Mui-disabled': {
            // cursor: 'not-allowed'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label[data-shrink="false"]': {
            transform: 'translate(14px, 11px)'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&[data-shrink="false"]': {
            transform: 'translate(14px, 11px)'
          }
        }
      }
    }
  }
})

export { defaultTheme }
