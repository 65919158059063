import * as Yup from "yup"
import { AcademicLevel, Accompagnateurs, Availabilities, CampaignId, Campus, Email, FirstName, LastName, MobilePhone, Salutation, Section, SlotId, Specialites } from "services/validation"

const schema = Yup.object().shape({
  ...Accompagnateurs,
  ...Availabilities,
  ...CampaignId,
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhone,
    ...Salutation,
    ...Section
  }),
  ...SlotId,
  ...Specialites
})

export default schema
