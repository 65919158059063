import { AcademicLevel, Accompagnateurs, Availabilities, CampaignId, Campus, Email, FirstName, LastName, MobilePhoneRequired, SlotId, WishedProgramm } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  ...Accompagnateurs,
  ...Availabilities,
  ...CampaignId,
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhoneRequired,
    ...WishedProgramm
  }),
  ...SlotId
})

export default schema
