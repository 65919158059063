import { CssBaseline } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import theme from "./Theme/index"
import { FormContextProvider } from "hooks/useForm"
import Forms from "../Forms"

const JourneesPortesOuvertesISGLuxury = () => {
  return (
    <FormContextProvider>
      <Forms>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <JourneesPortesOuvertes />
          </ThemeProvider>
        </StyledEngineProvider>
      </Forms>
    </FormContextProvider>
  )
}

export { JourneesPortesOuvertesISGLuxury }
