// Récupération du campus selon l'url d'une page
const findCampusFromUrl = (regex) => {
  const url = new URL(document.location.href)
  let pathname = url.pathname
  if (pathname.endsWith('/')) {
    pathname = pathname.slice(0, -1)
  }
  const page = pathname.split('/').pop()
  const result = regex.exec(page)

  if (result?.length >= 1 && result[1]) {
    return result[1].replace(/^\w/, c => c.toUpperCase())
  } else {
    return 'Paris'
  }
}

export { findCampusFromUrl }
