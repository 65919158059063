import PropTypes from "prop-types"
import { FormHelperText } from "@mui/material"
import { useFormContext } from "react-hook-form"
import emailSpellChecker from "@zootools/email-spell-checker"
import { useState } from "react"
import { Box } from "@mui/system"
import domains from "json/domains"
import InputText from "./InputText"

const Email = ({ control, label, name, ...rest }) => {

  const { setValue } = useFormContext()
  const [suggestion, setSuggestion] = useState('')

  const testDomainEmail = (e) => {
    setSuggestion('')
    emailSpellChecker.run({
      domains: domains,
      email: e.target.value,
      suggested: (suggestion) => {
        setSuggestion(suggestion.full)
      }
    })
  }

  const updateEmail = (email) => {
    setValue(name, email)
    setSuggestion('')
  }

  return (
    <>
      <InputText
        {...rest}
        control={control}
        label={label}
        name={name}
        onBlur={(e) => testDomainEmail(e)}
        type="email"
      />
      {suggestion !== '' && (
        <FormHelperText component="span" id="WrongDomain" sx={{ display: 'block', textAlign: 'right' }}>Vouliez-vous dire <Box onClick={() => updateEmail(suggestion)} sx={{ cursor: 'pointer', display: 'inline', textDecoration: 'underline', '&:hover': { textDecoration: 'none' } }}>{suggestion}</Box> ?</FormHelperText>
      )}
    </>
  )
}

Email.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default Email
