import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { AcademicLevel, Campus, ConsentementMultiple, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { consent, consentement, defaultValues, findCampusFromUrl, getCampus } from "services"
import MentionsLegales from "components/MentionsLegales"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...consent,
      campaignId: '',
      lead: {
        ...defaultValues,
        ...consentement
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, setValue, watch } = methods
  // State
  const [campaigns, setCampaigns] = useState([])
  const [campusParameter, setCampusParameter] = useState(null)
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId
    const campus = lead.Campus__c
    const defaultDataLead = {
      Division__c: 'ISEG MCS',
      IONISSchool__c: 'ISEG MCS',
      LastForm__c: 'Internet : demande de doc - GM',
      LeadSource: 'Internet: Demande de doc',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      const campaignMember = {
        CampaignId: campaignId,
        LeadId: leadCreated.Id,
        Status: 'Requested'
      }
      await salesforce.createCampaignMember(campaignMember)
      document.location.href=`${process.env.REACT_APP_ISEG_URL}/guide-metiers-retour-ok/?ville=${campus}&niveau=${encodeURIComponent(lead.AcademicLevel__c)}`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const campus = watch('lead.Campus__c')
  // useEffect
  useEffect(() => {
    // Récupération du campus
    const campus = findCampusFromUrl(/-([a-zA-Z]+)$/)
    const listeCampus = ['Bordeaux', 'Lille', 'Lyon', 'Nantes', 'Nice', 'Paris', 'Strasbourg', 'Toulouse']
    if (listeCampus.includes(campus)) {
      setValue('lead.Campus__c', findCampusFromUrl(/-([a-zA-Z]+)$/))
      setCampusParameter(campus)
    } else {
      setCampusParameter('')
    }
  }, [setValue])
  useEffect(() => {
    if (salesforce !== null) {
      const getData = async () => {
        const campaigns = await salesforce.getCampaigns('ISEG MCS', 'ISEG MCS', 'Documentation')

        return { campaigns }
      }

      getData().then((value) => {
        const { campaigns } = value
        setCampaigns(campaigns)
      }).catch((error) => {
        setError('Une erreur est survenue lors du chargement du formulaire', error)
      })
    }
  }, [salesforce, setError])
  useEffect(() => {
    if (campus !== '' && campaigns.length > 0) {
      const campaign = campaigns.find(el => el.Campus__c === campus)
      if (campaign !== undefined) {
        setValue('campaignId', campaign.Id)
      }
    }
  }, [campus, campaigns, setValue])
  useSalesforce('BusinessSchoolLead')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <Grid item sm={campusParameter === '' ? 6 : 12} xs={12}>
          <AcademicLevel school="ISEG MCS" section={false} specialites={false} />
        </Grid>
        {campusParameter === '' && (
          <Grid item sm={6} xs={12}>
            <Campus label="Campus souhaité" options={getCampus('ISEG MCS')} />
          </Grid>
        )}
        <ConsentementMultiple />
        <RequiredFields />
        <Submit label="Je télécharge le guide des métiers" onSubmit={onSubmit} />
        <MentionsLegales form="Documentation" school="ISEG MCS" />
      </Grid>
    </FormProvider>
  )
}

export default Form
