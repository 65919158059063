import { AcademicLevel, Accompagnateurs, Availabilities, CampaignId, Campus, Email, FirstName, LastName, MobilePhoneRequired, Section, SlotId } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  ...Accompagnateurs,
  ...Availabilities,
  ...CampaignId,
  consent: Yup.string().required('Veuillez faire un choix'),
  formations: Yup.array().min(1, 'Vous devez choisir au minimum une formation'),
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Campus,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhoneRequired,
    ...Section
  }),
  ...SlotId
})

export default schema
