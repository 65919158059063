import PropTypes from "prop-types"
import { Grid } from "@mui/material"
import { useFormContext } from "react-hook-form"
import Dropdown from "../fields/Dropdown"
import Specialites from "../Specialites"
import { useEffect, useState } from "react"
import { useForms } from "hooks/useForm"

const AcademicLevel = ({ academics, options, school, section = true, sectionRequired = true, specialites = true }) => {

  const { control, setValue, watch } = useFormContext()
  const { componentFetched, recordTypeId, salesforce, setComponentStates, setOpenSnackbar, setSnackMessage } = useForms()
  const [academicLevels, setAcademicLevels] = useState([])
  const [optionsAcademicLevels, setOptionsAcademicLevels] = useState([])
  const [optionsSections, setOptionsSections] = useState([])

  const academicLevel = watch('lead.AcademicLevel__c')
  // const recordTypeId = watch('lead.RecordTypeId')


  useEffect(() => {
    setComponentStates((prevState) => [
      ...prevState,
      { name: 'academicLevel', added: true, fetched: false },
    ])
  }, [setComponentStates])

  // Récupération des niveaux académiques
  useEffect(() => {
    if ((options === undefined || academics !== undefined) && recordTypeId !== '') {
      const getData = async () => {
        const academicLevels = await salesforce.getAcademicLevels(recordTypeId, school, academics)

        return { academicLevels }
      }

      getData().then((value) => {
        const { academicLevels } = value
        const finalAcademicLevels = academicLevels.map(el => (
          {
            label: el.academicLevel,
            value: el.academicLevel
          }
        ))
        setAcademicLevels(academicLevels)
        setOptionsAcademicLevels(finalAcademicLevels)
        componentFetched('academicLevel')
      }).catch((error) => {
        setOpenSnackbar(true)
        console.log('erreur', error)
        setSnackMessage('Une erreur est survenue lors du chargement du formulaire')
      })
    }
    if (options !== undefined) {
      componentFetched('academicLevel')
    }
  }, [recordTypeId])
  useEffect(() => {
    if (academicLevel !== '') {
      setValue('lead.Section__c', '')
      const sections = academicLevels.find(el => el.academicLevel === academicLevel)
      if (sections !== undefined) {
        setOptionsSections(sections.sections)
        setValue('sections', sections.sections)
      } else {
        setOptionsSections([])
        setValue('sections', [])
      }
    }
  }, [academicLevel])

  return (
    <>
      <Grid item sm={section ? 6 : 12} xs={12}>
        <Dropdown control={control} label="Études actuelles" name="lead.AcademicLevel__c" options={options || optionsAcademicLevels} required />
      </Grid>
      {section && (
        <Grid item sm={6} xs={12}>
          <Dropdown control={control} disabled={optionsSections.length === 0} label="Section" name="lead.Section__c" options={optionsSections} required={sectionRequired && (academicLevel === '' || optionsSections.length > 0)} />
        </Grid>
      )}
      {specialites && (
        <Specialites control={control} />
      )}
    </>
  )
}

AcademicLevel.propTypes = {
  academics: PropTypes.array,
  options: PropTypes.array,
  school: PropTypes.string,
  section: PropTypes.bool,
  sectionRequired: PropTypes.bool,
  specialites: PropTypes.bool
}

export default AcademicLevel
