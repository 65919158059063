import PropTypes from "prop-types"
import { FormControl, FormHelperText, InputLabel, OutlinedInput, Select } from "@mui/material"

const DropdownCommon = ({ disabled, error, label, multiple, name, onChange, renderOptions, required, value, ...rest }) => {
  return (
    <FormControl disabled={disabled} error={!!error} fullWidth>
      <InputLabel required={required} size="small">{label}</InputLabel>
      <Select
        {...rest}
        defaultValue={[]}
        displayEmpty={true}
        error={!!error}
        input={<OutlinedInput label={label} />}
        label={label}
        MenuProps={{ disablePortal: true }}
        name={name}
        multiple={!!multiple}
        onChange={onChange}
        required={required}
        size="small"
        value={value}
      >
        {renderOptions}
      </Select>
      <FormHelperText className={name} >{error !== undefined ? error.message : ''}</FormHelperText>
    </FormControl>
  )
}

DropdownCommon.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.object,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  renderOptions: PropTypes.array.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]).isRequired
}

export default DropdownCommon
