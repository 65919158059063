import PropTypes from "prop-types"
import { CssBaseline,  } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { default as Atelier } from "./Atelier/Form"
import { default as Conference } from "./Conference/Form"
import { default as Contact } from "./Contact/Form"
import { default as Documentation } from "./Documentation/Form"
import { default as JourneeImmersion } from "./JourneeImmersion/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import { default as LivreBlanc } from "./LivreBlanc/Form"
import { default as Precandidature } from "./Candidature/Form"
import theme from "./Theme/index"
import Forms from "../Forms"
import { FormContextProvider } from "hooks/useForm"

const AtelierEartsup = () => {
  return (
    <FormContextProvider>
      <Forms>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Atelier />
          </ThemeProvider>
        </StyledEngineProvider>
      </Forms>
    </FormContextProvider>
  )
}

const ConferenceEartsup = ({ type }) => {
  return (
    <FormContextProvider>
      <Forms>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Conference type={type} />
          </ThemeProvider>
        </StyledEngineProvider>
      </Forms>
    </FormContextProvider>
  )
}

const ContactEartsup = () => {
  return (
    <FormContextProvider>
      <Forms>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Contact />
          </ThemeProvider>
        </StyledEngineProvider>
      </Forms>
    </FormContextProvider>
  )
}

const DocumentationEartsup = () => {
  return (
    <FormContextProvider>
      <Forms>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Documentation />
          </ThemeProvider>
        </StyledEngineProvider>
      </Forms>
    </FormContextProvider>
  )
}

const JourneeImmersionEartsup = () => {
  return (
    <FormContextProvider>
      <Forms>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <JourneeImmersion />
          </ThemeProvider>
        </StyledEngineProvider>
      </Forms>
    </FormContextProvider>
  )
}

const JourneePortesOuvertesEartsup = () => {
  return (
    <FormContextProvider>
      <Forms>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <JourneesPortesOuvertes />
          </ThemeProvider>
        </StyledEngineProvider>
      </Forms>
    </FormContextProvider>
  )
}

const LivreBlancEtudiantEartsup = ({ campaignId }) => {
  return (
    <FormContextProvider>
      <Forms>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LivreBlanc campaignId={campaignId} />
          </ThemeProvider>
        </StyledEngineProvider>
      </Forms>
    </FormContextProvider>
  )
}

const LivreBlancParentEartsup = ({ campaignId }) => {
  return (
    <FormContextProvider>
      <Forms>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LivreBlanc campaignId={campaignId} parent={true} />
          </ThemeProvider>
        </StyledEngineProvider>
      </Forms>
    </FormContextProvider>
  )
}

const PrecandidatureEartsup = () => {
  return (
    <FormContextProvider>
      <Forms>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Precandidature />
          </ThemeProvider>
        </StyledEngineProvider>
      </Forms>
    </FormContextProvider>
  )
}

LivreBlancEtudiantEartsup.propTypes = {
  campaignId: PropTypes.string
}

LivreBlancParentEartsup.propTypes = {
  campaignId: PropTypes.string
}

ConferenceEartsup.propTypes = {
  type: PropTypes.string
}

export { AtelierEartsup, ConferenceEartsup, ContactEartsup, DocumentationEartsup, JourneeImmersionEartsup, JourneePortesOuvertesEartsup, LivreBlancEtudiantEartsup, LivreBlancParentEartsup, PrecandidatureEartsup }
